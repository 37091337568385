<template>
  <section class="game-details" v-if="getSelectedGame">
    <div class="gaming_details_container">
      <div class="gaming_overlay">
        <img :src="getSelectedGame.smallIconImageUrl" />
      </div>
      <div class="game_details_wrapper">
        <div class="game_header">
          <span @click="goBack">
            <img src="../assets/img/back_btn.png" alt />
          </span>
          <div class="game_title" v-if="getSelectedGame != null">
            <h3>{{ getSelectedGame.name }}</h3>
            <span class="title">{{ getSelectedContestid.description }}</span>
          </div>
          <!-- Share Button Commented -->
          <!-- <button type="button" name="button" class="share_btn" @click="share">
            <img src="../assets/img/share_earn.svg" alt />
            {{ $t("share") }}
          </button> -->
        </div>

        <ul class="play_game">
          <li>
            <div class="gaming_img">
              <img src="../assets/img/playwin.png" />
            </div>
            <!-- <div class="title">
              <h2>{{getSelectedContestid.prize}}</h2>
              <p>{{$t('TotalRewards')}}</p>
            </div>-->
            <button
              type="button"
              name="button"
              @click="isOpen = !isOpen"
              class="play_btn"
            >
              <img src="../assets/img/remote.png" alt />
              {{ !isOpen ? $t("Howtoplay") : $t("stopit") }}
            </button>
            <div class="game_status">
              <span class
                >{{ getSelectedContestid.currentPlayers }}/{{
                  getSelectedContestid.playersLimit
                }}</span
              >
              <span class
                >{{ getTimeString(getSelectedContestid.startTime) }} -
                {{ getTimeString(getSelectedContestid.endTime) }}</span
              >
            </div>
          </li>
          <div id="game_video" v-if="isOpen">
            <iframe
              width="100%"
              height="100%"
              frameborder="0"
              :src="getYouTubeVideo()"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; allowfullscreen"
              allowfullscreen
            ></iframe>
          </div>
        </ul>

        <div class="cash-dist-panel">
          <div class="prize_row">
            <h4>{{ $t("Amountdistribution") }}</h4>
            <div
              class="prize_col"
              v-for="(r, i) in getSelectedContestid.ranges"
              :key="i"
            >
              <h5>
                {{
                  (getSelectedContestid.hasOwnProperty("rangeType") &&
                    getSelectedContestid.rangeType === "RANK") ||
                  !getSelectedContestid.hasOwnProperty("rangeType")
                    ? `${$t("rank")} ${r.min} - ${r.max} `
                    : `${i === 0 ? $t("top") : $t("next")} ${r.max - r.min}%`
                }}
              </h5>
              <span class="wallet-icon">
                {{ r.prize }}
                <img
                  class="icon_text"
                  src="../assets/img/star.png"
                  width="20px"
                  v-if="r.creditType == 'MCASH'"
                />
                <img
                  class="icon_text"
                  src="/static/images/shoping_wallet.svg"
                  width="20px"
                  v-else-if="r.creditType == 'SWALLET'"
                />

                <img
                  class="icon_text"
                  src="/static/images/rupee.svg"
                  width="20px"
                  v-else
                />
              </span>
            </div>
          </div>
          <div class="play_text_message">
            <p v-if="getSelectedContestid.fee == 0">{{ $t("p5") }}</p>
            <!-- <p class="text_color">
              {{$t('refund')}}
              <b>{{getSelectedContestid.refundAmount}} GEMS</b>
            </p>-->

            <div class="user-walletmsg">
              जीतने के लिए न्यूनतम
              {{ getSelectedContestid.minPlayers }} खिलाड़ियों की आवश्यकता होती
              है अन्यथा ज्वाइनिंग फीस वापस कर दी जाएगी।
            </div>

            <p v-if="getSelectedContestid.feeType == 'GEMS'">
              {{ `( ${$t("winAmtMsg")} )` }}
            </p>
            <p v-else-if="getSelectedContestid.feeType == 'MCASH'">
              {{ `( ${$t("winAmtMsgMcash")} )` }}
            </p>
          </div>
          <!-- after game scrore -->
          <!--   -->
          <div
            class="user_dashbrd"
            v-if="contestuserInfo && contestuserInfo.playCount >= 1"
          >
            <div class="user-point-view" v-if="contestuserInfo">
              <div class="point-col">
                <span class="user-icon">
                  <img
                    :src="contestuserInfo.nearScorerDatas[0].profileImageUrl"
                    v-if="
                      contestuserInfo.nearScorerDatas[0] &&
                        contestuserInfo.nearScorerDatas[0].profileImageUrl
                    "
                  />
                  <img src="../assets/img/small_user_img.png" v-else />
                </span>
                <span class="user-rank">
                  <span>{{
                    contestuserInfo.nearScorerDatas[0]
                      ? contestuserInfo.nearScorerDatas[0].prize
                      : 0
                  }}</span>

                  <template v-if="contestuserInfo.nearScorerDatas[0]">
                    <span v-if="getContestUserInfo.creditType">
                      <span
                        class="rupeeClass"
                        v-if="getContestUserInfo.creditType === 'WALLET'"
                      >
                        ₹
                      </span>
                      <span class="starClass" v-else
                        ><img
                          src="/static/images/rupee.svg"
                          width="20"
                          height="20"
                          class="wallet-icn"
                      /></span>
                    </span>
                  </template>
                </span>
                <span class="user-title">{{
                  contestuserInfo.nearScorerDatas[0]
                    ? contestuserInfo.nearScorerDatas[0].name
                    : $t("noOpponent")
                }}</span>
                <span
                  v-if="contestuserInfo.nearScorerDatas[0]"
                  class="user-value"
                  >{{ $t("CurrentRank")
                  }}{{ contestuserInfo.nearScorerDatas[0].rank }}</span
                >
                <span
                  v-if="contestuserInfo.nearScorerDatas[0]"
                  class="user-value"
                  >{{ $t("score") }}
                  {{ contestuserInfo.nearScorerDatas[0].score }}</span
                >
              </div>
              <div class="point-col high-score-active">
                <span class="user-icon">
                  <img
                    src="../assets/img/small_user_img.png"
                    v-if="contestuserInfo.profileImageUrl == null"
                  />
                  <img :src="contestuserInfo.profileImageUrl" v-else />
                </span>
                <span class="user-rank">
                  <span>{{ contestuserInfo.prize }}</span>

                  <template v-if="getContestUserInfo.creditType">
                    <span
                      class="rupeeClass"
                      v-if="getContestUserInfo.creditType === 'WALLET'"
                    >
                      ₹
                    </span>
                    <span class="starClass" v-else
                      ><img
                        src="/static/images/rupee.svg"
                        width="20"
                        height="20"
                        class="wallet-icn"
                    /></span>
                  </template>
                </span>
                <span class="user-title">{{ contestuserInfo.name }}</span>
                <span class="user-value"
                  >{{ $t("CurrentRank") }}{{ contestuserInfo.rank }}</span
                >
                <span class="user-value"
                  >{{ $t("score") }} {{ contestuserInfo.score }}</span
                >
              </div>
              <div class="point-col second_score">
                <span class="user-icon">
                  <img
                    :src="contestuserInfo.nearScorerDatas[1].profileImageUrl"
                    v-if="
                      contestuserInfo.nearScorerDatas[1] &&
                        contestuserInfo.nearScorerDatas[1].profileImageUrl
                    "
                  />
                  <img src="../assets/img/small_user_img.png" v-else />
                </span>
                <span class="user-rank">
                  <span>{{
                    contestuserInfo.nearScorerDatas[1]
                      ? contestuserInfo.nearScorerDatas[1].prize
                      : 0
                  }}</span>

                  <template v-if="contestuserInfo.nearScorerDatas[1]">
                    <span v-if="getContestUserInfo.creditType">
                      <span
                        class="rupeeClass"
                        v-if="getContestUserInfo.creditType === 'WALLET'"
                      >
                        ₹
                      </span>
                      <span class="starClass" v-else
                        ><img
                          src="/static/images/rupee.svg"
                          width="20"
                          height="20"
                          class="wallet-icn"
                      /></span>
                    </span>
                  </template>
                </span>
                <span class="user-title">{{
                  contestuserInfo.nearScorerDatas[1]
                    ? contestuserInfo.nearScorerDatas[1].name
                    : $t("noOpponent")
                }}</span>
                <span
                  v-if="contestuserInfo.nearScorerDatas[1]"
                  class="user-value"
                  >{{ $t("CurrentRank")
                  }}{{ contestuserInfo.nearScorerDatas[1].rank }}</span
                >
                <span
                  v-if="contestuserInfo.nearScorerDatas[1]"
                  class="user-value"
                  >{{ $t("score") }}
                  {{ contestuserInfo.nearScorerDatas[1].score }}</span
                >
              </div>
            </div>
          </div>
          <!-- after game scrore  -->
        </div>

        <div class="bottm_fix_section">
          <span class="last_time"
            >{{ $t("Lasttimetojoin") }}
            {{ getTimeString(getSelectedContestid.joinEndTime) }}
            {{ $t("is") }}</span
          >
          <div class="play_buttons">
            <button
              type="button"
              @click="joinGame($route.params.contestId)"
              class="grad_green_btn"
              v-bind:style="{
                display:
                  joined || getSelectedContestid.status === 'SCHEDULED'
                    ? 'none'
                    : 'block'
              }"
            >
              {{ $t("Play") }}
              <img
                v-if="
                  getSelectedContestid.feeType == 'MCASH' ||
                    getSelectedContestid.feeType == 'SWALLET'
                "
                class="money-icon"
                src="/static/images/shoping_wallet.svg"
              />
              <img class="money-icon" v-else src="/static/images/rupee.svg" />
              {{ getSelectedContestid.fee }} {{ $t("In") }}
            </button>
            <button
              type="button"
              @click="playGame"
              class="grad_green_btn"
              v-bind:style="{ display: !joined ? 'none' : 'block' }"
            >
              {{ $t("Play") }}
            </button>
          </div>
          <div class="game_status">
            <span v-if="getSelectedContestid.status == 'SCHEDULED'">
              {{ $t("Gameisstarting") }}
              <span class="timer">{{
                remainingTimeString(getSelectedContestid.startTime)
              }}</span>
              {{ $t("In") }}
            </span>
            <span v-if="getSelectedContestid.status == 'LIVE'">
              {{ $t("Gameisending") }}
              <span class="timer">{{
                remainingTimeString(getSelectedContestid.endTime)
              }}</span>
              {{ $t("In") }}
            </span>
          </div>
          <div class="loading-spinner">
            <circle-spin v-if="spinnerLoading"></circle-spin>
          </div>

          <div v-if="!playersFull">{{ $t("p4") }}</div>
          <notifications
            classes="vue-notification-style animated fadeInRight"
            position="top right"
            group="low-mcash"
          />

          <notifications
            classes="vue-error-notification-style animated fadeInRight"
            position="top right"
            group="other-error"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import constants from "../constants";
import { mapState } from "vuex";
import joinGame from "../mixins/joinGame";

export default {
  name: "Details",
  data() {
    return {
      userTime: 0,
      games: [],
      contests: null,
      selected: 0,
      selectedContest: null,
      loading: true,
      userInfo: null,
      joined: false,
      spinnerLoading: false,
      carouselgames: [],
      isOpen: false,
      showModal: false,
      chats: [],
      chatMsg: ""
    };
  },
  mixins: [joinGame],
  mounted() {
    var _this = this;
    this.changeLocale();
    this.getUserGameInfo();
    // console.log("======================", this.contestuserInfo);
    this.$store.commit("saveContestUserInfo", {
      contestuserInfo: null
    });
    console.log(this.getSelectedContestid);
    // this.connectToChat();
  },
  methods: {
    updateContestInfo() {
      this.$http
        .get(
          constants.CONTESTSURL +
            this.$route.params.contestId +
            "?q=" +
            new Date().getTime()
        )
        .then(data => {
          // console.log(data.body);
          this.$store.commit("saveContestUserInfo", {
            contestuserInfo: data.body
          });
        });
    },
    changeLocale(locale) {
      this.$i18n.locale = this.$cookie.get("lang") || "hi";
      if (this.$cookie.get("lang") == "ml") {
        document.body.classList.add("activeMultiLang");
      }
      console.log({ lang: this.$cookie.get("lang") });
    },

    getBgColor(gamecolor) {
      return {
        background: `linear-gradient(100deg,${gamecolor}, ${gamecolor}, transparent)`
      };
    },

    hideMobile(username) {
      if (new RegExp(/^[0][6-9]\d{9}$|^[6-9]\d{9}$/g).test(username)) {
        var res = username.substring(7, username.length);
        var hiddenmobile = "*******" + res;
        return hiddenmobile;
      } else {
        return username;
      }
    },

    addChat() {
      var obj = {
        name: this.$store.getters.contestuserInfo.name,
        contestReferenceId: this.$route.params.contestId,
        t: this.chatMsg,
        profileImageUrl: this.$store.getters.contestuserInfo.profileImageUrl
      };
      this.$http.post(constants.MESSAGEPUSHURL, obj).then(
        function(data) {
          this.chatMsg = "";
        },
        response => {
          this.$notify({
            group: "low-mcash",
            text: "Something went wrong"
          });
        }
      );
    },
    getTime(timestamp) {
      var _time = new Date(timestamp).toLocaleTimeString();
      return _time;
    },

    openProfile(xAuthId) {
      this.xAuthId = xAuthId;
      try {
        JSBridgePlugin.showNativeProfileView(xAuthId);
      } catch (err) {}
    },

    goBack() {
      this.database.ref(`gameContests/${this.$route.params.gameid}`).off();
      this.$router.go(-1);
    },

    getUserGameInfo() {
      var _this = this;
      if (_this.$store.getters.games[_this.$route.params.gameid] == null) {
        setTimeout(function() {
          _this.getUserGameInfo();
        }, 200);
      } else {
        var url =
          "contest_qualified_users/" +
          _this.$route.params.contestId +
          "/" +
          _this.$cookie.get("X-Auth-Id");
        if (
          _this.$store.getters.games[_this.$route.params.gameid].contests[
            _this.$route.params.contestId
          ].status == "LIVE"
        ) {
          _this.database
            .ref(url)
            .once("value")
            .then(function(snapshot) {
              if (snapshot.val() != null) {
                _this.$data.joined = true;
                // update contest info if user is joined
                _this.updateContestInfo();
              } else {
                // this.spinnerLoading = true;
                _this.$data.joined = false;
              }
              _this.$data.loading = false;
            });
        } else {
          // console.log("in else");
          _this.$data.loading = true;
        }
      }
    },
    share() {
      var name = this.getSelectedGame.name;
      this.$http
        .get(
          constants.PAYBOARDTESTURL +
            `/PayBoard/rest/commons/fetchMiniappShareMessage?gameName=${name}`
        )
        .then(function(data) {
          var obj = {
            message: data.body,
            imageUrl: "",
            isStatusShare: true
          };
          // console.log("==obj==", obj, JSON.stringify(obj));
          try {
            JSBridgePlugin.share(JSON.stringify(obj));
          } catch (err) {
            console.log(err);
            // console.log(obj);
          }
        });
    },

    clearSelected() {
      this.$data.selectedContest = null;
      this.$data.userInfo = null;
      this.$data.joined = false;
    },

    getModal: function() {
      showModal: true;
    },

    getYouTubeVideo: function() {
      var url =
        "https://www.youtube.com/embed/" +
        this.$store.getters.games[this.$route.params.gameid].videoId;
      return url;
    },

    playersFull() {
      if (
        this.selectedContest.currentPlayers < this.selectedContest.playersLimit
      ) {
        return true;
      } else {
        return false;
      }
    },

    openGame(game) {
      this.$data.selectedContest = game;

      var url =
        "contest_qualified_users/" +
        game.referenceId +
        "/" +
        this.$cookie.get("X-Auth-Id");

      var _this = this;
      if (game.status == "LIVE") {
        this.database
          .ref(url)
          .once("value")
          .then(function(snapshot) {
            if (snapshot.val() != null) {
              _this.$data.joined = true;
              _this.$http.get(constants.CONTESTSURL + game.referenceId).then(
                function(data) {
                  _this.$data.userInfo = data.body;
                },
                reponse => {}
              );
            } else {
              _this.$data.joined = false;
            }
            _this.$data.loading = false;
          });
      } else {
        _this.$data.loading = true;
      }
    },

    playGame() {
      //  constants.CONTESTSURL +
      //     this.$route.params.contestId +
      //     "?q=" +
      //     new Date().getTime()
      if (window) {
        window.scrollTo(0, 0);
      }
      this.spinnerLoading = true;
      this.$http
        .post(constants.CONTESTSURL + `${this.$route.params.contestId}/player`)
        .then(
          function(data) {
            this.$router.push({
              name: "PlayGame",
              query: {
                gameUrl: encodeURIComponent(data.body.game.url),
                contestId: this.$route.params.contestId
              }
            });

            // if (
            //   this.$cookie.get("app-name") === "browser91" &&
            //   this.$cookie.get("version-code") > 19
            // ) {
            try {
              JSBridgePlugin.openInterstitialAd(
                '[{"adType":"INMOBI","adUnitId":"1632497049167","accountId":"a7a85c0290704cbdbf970545cdcd6de"}]'
              );
              console.log("ad display");
            } catch (err) {
              console.log(err);
              console.log("ad display error", err);
            }
            // }

            /*if(this.$cookie.get("X-Auth-Id") == "8f14e45fceea167a5a36dedd4bea2543" || this.$cookie.get("X-Auth-Id") == "da4fb5c6e93e74d3df8527599fa62642" ||this.$cookie.get("X-Auth-Id") ==  "c81e728d9d4c2f636f067f89cc14862c"){
              // window.location.hash += _.substr(_.indexOf("?"),_.length)
              // window.location.pathname = _.substring(_.indexOf("1/")+1,_.lastIndexOf("/"))
              //JSBridgePlugin.openURL(_)
              window.open(_)
            }else{
              window.location.href = data.body.game.url
            } */

            //a.substring(a.indexOf("1/")+1,a.lastIndexOf("/"))
            // window.open(data.body.game.url)
            //location.href =  "http://tournament.money91.com/DontCrash/?uid=da4fb5c6e93e74d3df8527599fa62642&gameid=35"//data.body.game.url;
          },
          response => {
            //console.log(response);
            this.$notify({
              group: "low-mcash",
              text: data.data.failMessage
            });
            this.spinnerLoading = false;
          }
        );
    },

    getHomeData() {
      this.$http.get(constants.CONTESTSURL).then(
        function(data) {},
        response => {}
      );
    },

    getTimeString(time) {
      var _ = new Date(time);
      var min =
        _.getMinutes() < 10 ? "0" + _.getMinutes() : "" + _.getMinutes();
      return _.getHours() + ":" + min;
    },
    remainingTimeString(time) {
      var a = time;
      var b = new Date().getTime();

      var distance = a - b;

      var hrs = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var min = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var sec = Math.floor((distance % (1000 * 60)) / 1000);
      return hrs + " Hours " + min + " Minutes " + sec + " Secs";
    }
  },

  computed: {
    ...mapState(["contestuserInfo"]),
    getUserSysTime() {
      var userTime = +new Date();
      this.$data.userTime = userTime;
    },

    getSelectedGame: function() {
      return this.$store.getters.games[this.$route.params.gameid];
    },

    getSelectedContestid: function() {
      //Get selected contest referenceId
      if (
        this.$route.params.contestId in
        this.$store.getters.games[this.$route.params.gameid].contests
      ) {
        return this.$store.getters.games[this.$route.params.gameid].contests[
          this.$route.params.contestId
        ];
      } else {
        return {};
      }
    },

    getUserInfo: function() {
      return this.$store.getters.userInfo;
    },

    getContestUserInfo: function() {
      return this.$store.getters.contestuserInfo;
    }
  }
};
</script>

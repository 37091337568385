import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  en: {
    tournament: 'Tournament',
    noOpponent: 'no opponent',
    Playandwin: 'Play and win',
    Weekly: 'Weekly',
    TillNow: 'Till',
    Playing: 'Playing',
    Youareplayingagame: 'You are playing game',
    MyAccount: 'My Account',
    Tournamentisover: 'Tournament is over',
    TotalRewards: 'Total Rewards',
    Entryfees: 'Entry fees',
    Yourrank: 'Your Rank',
    YouWon: 'You Won',
    Battle: 'Battle',
    Howtoplay: 'How to play',
    stopit: 'Stop It',
    share: 'Share and Earn Gems',
    CashWinner: 'GEMS Winner',
    LastTime: 'Last time to join the game',
    Amountdistribution: 'Amount distribution',
    Leaderboard: 'Leaderboard',
    chat: 'Chat',
    rules: 'Rules',
    p1:
      'This battle will be between two people. Both players will increase their score by playing games for 2 minutes.',
    p2:
      'The scores of all played games will be added, depending on the basis of which will be won by both the players.',
    p3:
      'The time to find the competitor is 1 minute. Your GEMS will not be cut if the contestant gets it. During the battle you can not go out of the battle.',
    YourSuccess: 'Your Success',
    Play: 'Play',
    In: 'in',
    Gameisstarting: 'Game is starting',
    Gameisending: 'Game is ending',
    p4: 'You can not participate in this tournament. The players are full.',
    Tryagain: 'Try again',
    Finding: 'Finding your competitor ...',
    From: 'From',
    Opponentgot: 'Opponent got',
    Playagain: 'Play again',
    Youropponent: 'Your opponent',
    Yourgamebegin: 'Your game is about to begin ...',
    Gameendedrank: 'Game ended. Your rank',
    competitorrank: 'And your competitor rank',
    is: 'is',
    Willruntill: 'will run till',
    p5:
      'If you play this tournament 50 times, the tournament fee will be refunded to your wallet.',
    refund: 'Refund:',
    Youplaythis: 'You play this',
    Haveplayed: 'Have played.',
    Lasttimetojoin: 'Last time to join the game',
    CurrentRank: 'Current Rank:',
    score: 'score:',
    rank: 'rank:',
    yourrank: 'Your Rank',
    totalprice: 'Total price',
    Period: 'Period',
    Itsgoing: 'Its going on',
    aboutobegin: 'Is about to begin',
    free: 'Free',
    Mahasangram: 'Mahasangram',
    Lasttime: 'Last time to join the game',
    haveplay: 'Have played',
    totalscore: 'Total score',
    you: 'You',
    p6: 'Have played the game. Back to the Mahasangram to play further.',
    Youenter: 'You enter 1 entry',
    Canplay: 'Can play times (multiple entries are valid).',
    scorefor:
      'Score for all the games played will be added, depending on your rank will be determined.',
    home: 'Home',
    mytournaments: 'My Tournaments',
    Yourscore: 'Your Score',
    p7: 'You are not playing any games.',
    p8: 'You have no further players.',
    p9: 'After playing the bar you will have to join the Mahasangram.',
    wallet: 'Wallet',
    cash: 'GEMS',
    howtoplay: 'How to play',
    Youplaythisgame: 'You play this game',
    win: 'Win',
    AllGame: 'All Games',
    groupChat: 'Group Chat',
    chatGroupList: 'Chat Group List',
    winAmtMsg:
      'The GEMS of victory will be transferred to your Mall91 wallet sometime',
      winAmtMsgMcash:'The MCASH of victory will be transferred to your Mall91 wallet sometime',
    audioChatIn: 'Top 5 Players Get the chance to do audio chat',
    audioChatOut:
      'Out of the top 5, you have been selected for the chat room, talk about it',
    top: 'Top',
    next: 'Next',
    joinGrpChat: "Join group chat",
    casinoGames: 'New Games',
    boardGames: 'Board Games',
    favouriteGames: 'Favourite Games',
    cricketGames: 'Cricket Games',
    gems: 'GEMS',
  },
  bn: {
    tournament: 'টুর্নামেন্ট',
    noOpponent: 'কোন বিরোধী',
    Playandwin: 'খেলুন এবং জয়',
    Weekly: 'সাপ্তাহিক',
    TillNow: 'এখন পর্যন্ত',
    Playing: 'কেলি',
    Youareplayingagame: 'আপনি একটি খেলা বাজানো হয়',
    MyAccount: 'আমার অ্যাকাউন্ট',
    Tournamentisover: 'টুর্নামেন্ট শেষ হয়',
    TotalRewards: 'মোট পুরষ্কার',
    Entryfees: 'ভর্তি ফি',
    Yourrank: 'আপনার পদ',
    YouWon: 'আপনি জিতেছেন',
    Battle: 'যুদ্ধ',
    Howtoplay: 'কিভাবে খেলা',
    stopit: 'ঘনিষ্ঠ',
    share: 'শেয়ার করুন এবং এম ক্যাশ উপার্জন করুন',
    CashWinner: 'নগদ বিজয়ী',
    LastTime: 'খেলা যোগদান শেষ সময়',
    Amountdistribution: 'পরিমাণ বিতরণ',
    Leaderboard: 'লিডারবোর্ড',
    chat: 'চ্যাট করুন',
    rules: 'বিধি',
    p1:
      'এই যুদ্ধ দুই মানুষের মধ্যে হতে হবে। উভয় খেলোয়াড় 2 মিনিটের জন্য গেম খেলতে তাদের স্কোর বৃদ্ধি করবে।',
    p2:
      'সমস্ত খেলানো গেম স্কোর স্কোর করা হবে, ভিত্তিতে ভিত্তিতে যা উভয় খেলোয়াড় দ্বারা জিততে হবে।',
    p3:
      'প্রতিযোগী খুঁজে পেতে সময় 1 মিনিট। প্রতিযোগী এটি পায় যদি আপনার এম ক্যাশ কাটা হবে না। যুদ্ধের সময় আপনি যুদ্ধ বাইরে যেতে পারেন না।',
    YourSuccess: 'আপনার বিজয়',
    Play: 'অভিনীত',
    In: 'উপর',
    Gameisstarting: 'খেলা শুরু হয়',
    Gameisending: 'খেলা শেষ হয়',
    p4: 'আপনি এই টুর্নামেন্টে অংশগ্রহণ করতে পারবেন না। খেলোয়াড়রা পূর্ণ।',
    Tryagain: 'আবার চেষ্টা করুন',
    Finding: 'আপনার প্রতিদ্বন্দ্বী খুঁজুন ...',
    Opponentgot: 'প্রতিপক্ষ পেয়েছিলাম',
    Playagain: 'আবার খেলুন',
    Youropponent: 'আপনার প্রতিপক্ষ',
    Yourgamebegin: 'আপনার খেলা শুরু করতে চলেছে ...',
    Gameendedrank: 'খেলা শেষ। আপনার পদ',
    competitorrank: 'এবং আপনার প্রতিযোগীদের র্যাঙ্ক',
    is: 'সেখানে আছে',
    From: 'থেকে',
    Willruntill: 'পর্যন্ত চালানো হবে',
    p5:
      'যদি আপনি এই টুর্নামেন্টটি 50 বার খেলেন তবে টুর্নামেন্ট ফি আপনার ওয়ালেটে ফিরিয়ে আনা হবে।',
    refund: 'এটির পরিশোধ:',
    Youplaythis: 'আপনি এই খেলা',
    Haveplayed: 'খেলেছে',
    Lasttimetojoin: 'খেলা যোগদান শেষ সময়',
    CurrentRank: 'বর্তমান অবস্থান:',
    score: 'স্কোর:',
    rank: 'বিভাগ:',
    yourrank: 'তোমার বিভাগ',
    totalprice: 'মোট খরচ',
    Period: 'কাল',
    Itsgoing: 'চলমান',
    aboutobegin: 'শুরু সম্পর্কে',
    free: 'বিনামূল্যে',
    Mahasangram: 'যুদ্ধক্ষেত্র',
    Lasttime: 'খেলা যোগদান শেষ সময়',
    haveplay: 'খেলেছে',
    totalscore: 'সম্পূর্ণ ফলাফল',
    you: 'আপনি',
    p6: 'খেলা খেলেছে। মহাসংগ্রামে আবার খেলতে।',
    Youenter: 'আপনি 1 এন্ট্রি লিখুন',
    Canplay: 'সময় খেলতে পারেন (একাধিক এন্ট্রি বৈধ)।',
    scorefor:
      'খেলার সব খেলা জন্য স্কোর যোগ করা হবে, আপনার পদ উপর নির্ভর করে নির্ধারিত হবে।',
    home: 'বাড়ি',
    mytournaments: 'আমার টুর্নামেন্ট',
    Yourscore: 'তোমার ফলাফল',
    p7: 'আপনি কোন গেম খেলে না।',
    p8: 'আপনি কোন অতিরিক্ত খেলোয়াড় আছে।',
    p9: 'বারটি খেলার পর আপনাকে মহাসংগ্রামে যোগ দিতে হবে।',
    wallet: 'মানিব্যাগ',
    cash: 'নগদ',
    howtoplay: 'কিভাবে খেলা',
    Youplaythisgame: 'আপনি এই খেলা খেলা',
    win: 'জয়',
    AllGame: 'সব খেলা',
    groupChat: 'গ্রুপ চ্যাট',
    chatGroupList: 'চ্যাট গ্রুপ তালিকা',
    winAmtMsg: 'বিজয় পরিমাণ আপনার Mall91 ওয়ালেট স্থানান্তরিত করা হবে ।',
    winAmtMsgMcash:'বিজয় পরিমাণ আপনার Mall91 ওয়ালেট স্থানান্তরিত করা হবে ।',
    audioChatIn:
      'শীর্ষ 5 টি থেকে আপনাকে চ্যাট রুমের জন্য নির্বাচিত করা হয়েছে, দয়া করে কথা বলুন।',
    audioChatOut: 'শীর্ষ 5 খেলোয়াড়দের অডিও চ্যাট করার সুযোগ পাবেন।',
    top: 'শীর্ষ',
    next: 'পরবর্তী',
    joinGrpChat: "গ্রুপ চ্যাট যোগদান",
    casinoGames: 'নাচঘর খেলা',
    boardGames: 'বোর্ড গেম',
    favouriteGames: 'প্রিয় গেমস',
    cricketGames: 'ক্রিকেট গেমস',
    gems: 'জেমস',
  },

  gu: {
    tournament: 'પ્રતયોગીતા',
    Playandwin: 'રમો અને જીત',
    noOpponent: 'કોઈ વિરોધી નથી',
    Weekly: 'સાપ્તાહિક',
    TillNow: 'અત્યાર સુધી',
    Playing: 'વગાડવા',
    Youareplayingagame: 'તમે રમત રમી રહ્યા છો',
    MyAccount: 'મારું ખાતું',
    Tournamentisover: 'ટુર્નામેન્ટ સમાપ્ત થઈ ગયું છે',
    TotalRewards: 'કુલ પુરસ્કારો',
    Entryfees: 'એડમિશન ફી',
    Yourrank: 'તમારો ક્રમ',
    YouWon: 'તમે જીતી લીધું',
    Battle: 'યુદ્ધ',
    Howtoplay: 'કેવી રીતે રમવું',
    stopit: 'બંધ કરો',
    share: 'શેર કરો અને એમ કેશ કમાઓ',
    CashWinner: 'કેશ વિજેતા',
    LastTime: 'રમતમાં જોડાવા માટે છેલ્લું સમય',
    Amountdistribution: 'રકમ વિતરણ',
    Leaderboard: 'લીડરબોર્ડ',
    chat: 'ચેટ કરો',
    rules: 'નિયમો',
    p1:
      'આ યુદ્ધ બે લોકો વચ્ચે હશે. બંને ખેલાડીઓ 2 મિનિટ માટે રમતો રમીને પોતાનો સ્કોર વધારશે.',
    p2:
      'તમામ રમી રમતોના સ્કોર્સ ઉમેરવામાં આવશે, જેના આધારે બંને ખેલાડીઓ દ્વારા જીતી લેવામાં આવશે.',
    p3:
      'સ્પર્ધક શોધવાનો સમય 1 મિનિટનો છે. સ્પર્ધકને તે મળે તો તમારું એમ-કેશ કાપવામાં આવશે નહીં. યુદ્ધ દરમિયાન તમે યુદ્ધમાંથી બહાર નીકળી શકતા નથી.',
    YourSuccess: 'તમારી જીત',
    Play: 'રમાય છે',
    In: 'માં',
    Gameisstarting: 'આ રમત શરૂ થાય છે',
    Gameisending: 'આ રમત સમાપ્ત થાય છે',
    p4: 'તમે આ ટુર્નામેન્ટમાં ભાગ લઈ શકતા નથી. ખેલાડીઓ સંપૂર્ણ છે.',
    Tryagain: 'ફરીથી પ્રયાસ કરો',
    Finding: 'તમારા પ્રતિસ્પર્ધીને શોધવું ...',
    Opponentgot: 'વિરોધી મળ્યો',
    Playagain: 'ફરી રમો',
    Youropponent: 'તમારા વિરોધી',
    Yourgamebegin: 'તમારી રમત શરૂ થવાની છે ...',
    Gameendedrank: 'આ રમત સમાપ્ત થઈ. તમારો ક્રમ',
    competitorrank: 'અને તમારા સ્પર્ધકોની રેન્ક',
    is: 'ત્યાં છે',
    From: 'પ્રતિ',
    Willruntill: 'ત્યાં સુધી ચાલશે',
    p5:
      'જો તમે આ ટુર્નામેન્ટ 50 વખત રમશો, તો ટુર્નામેન્ટ ફી તમારા વૉલેટ પર પરત કરવામાં આવશે.',
    refund: 'આનું વળતર:',
    Youplaythis: 'તમે આ રમે છે',
    Haveplayed: 'રમ્યા છે',
    Lasttimetojoin: 'રમતમાં જોડાવા માટે છેલ્લું સમય',
    CurrentRank: 'વર્તમાન ક્રમ:',
    score: 'સ્કોર:',
    rank: 'શ્રેણી:',
    yourrank: 'તમારા શ્રેણી',
    totalprice: 'કુલ ખર્ચ',
    Period: 'સમયગાળો',
    Itsgoing: 'ચાલી રહેલ',
    aboutobegin: 'શરૂ કરવા માટે છે',
    free: 'મફત',
    Mahasangram: 'મહાસંઘમ',
    Lasttime: 'રમતમાં જોડાવા માટે છેલ્લું સમય',
    haveplay: 'રમ્યા છે',
    totalscore: 'કુલ સ્કોર',
    you: 'તમે',
    p6: 'આ રમત રમી છે. આગળ રમવા માટે મહાસંઘમ પાછા.',
    Youenter: 'તમે 1 એન્ટ્રી દાખલ કરો છો',
    Canplay: 'સમય રમી શકે છે (બહુવિધ પ્રવેશો માન્ય છે).',
    scorefor:
      'રમી બધી રમતો માટેનો સ્કોર ઉમેરવામાં આવશે, તમારા રેન્કને આધારે નક્કી કરવામાં આવશે.',
    home: 'ઘર',
    mytournaments: 'મારા ટુર્નામેન્ટ્સ',
    Yourscore: 'તમારો સ્કોર',
    p7: 'તમે કોઈપણ રમતો રમી રહ્યા નથી.',
    p8: 'તમારી પાસે કોઈ વધુ ખેલાડીઓ નથી.',
    p9: 'બાર રમ્યા પછી તમારે મહાસંઘમમાં જોડાવું પડશે.',
    wallet: 'વૉલેટ',
    cash: 'રોકડ',
    howtoplay: 'કેવી રીતે રમવું',
    Youplaythisgame: 'તમે આ રમત રમે છે',
    win: 'જીતી',
    AllGame: 'બધી રમતો',
    groupChat: 'ગ્રુપ ચેટ',
    chatGroupList: 'ચેટ ગ્રુપ સૂચિ',
    winAmtMsg:
      'વિજયની રકમ તમારા Mall91 વોલેટ થોડીવારમાં તબદીલ કરવામાં આવશે.',
    winAmtMsgMcash:'વિજયની રકમ તમારા Mall91 વોલેટ થોડીવારમાં તબદીલ કરવામાં આવશે.',
    audioChatIn:
      'ટોચની 5 માંથી તમને ચેટ રૂમ માટે પસંદ કરવામાં આવ્યા છે, કૃપા કરી વાત કરો.',
    audioChatOut: 'ટોપ 5 ખેલાડીઓને ઓડિયો ચેટ કરવાની તક મળે છે.',
    top: 'ટોચ',
    next: 'આગળ',
    joinGrpChat: "જૂથ ચેટમાં જોડાઓ",
    casinoGames: 'કેસિનો રમતો',
    boardGames: 'બોર્ડ રમતો',
    favouriteGames: 'પ્રિય રમતો',
    cricketGames: 'ક્રિકેટ ગેમ્સ',
    gems: 'જેમ્સ',
  },
  hi: {
    tournament: 'टूर्नामेंट ',
    Playandwin: 'खेलो और जीतो',
    noOpponent: 'कोई प्रतिद्वंद्वी नहीं',
    Weekly: 'साप्ताहिक',
    TillNow: 'अब तक',
    Playing: 'खेल रहे है',
    Youareplayingagame: 'आप गेम खेल रहे है',
    MyAccount: 'मेरा खाता',
    Tournamentisover: ' टूर्नामेंट पूरा हुआ',
    TotalRewards: 'कुल पुरस्कार ',
    Entryfees: 'प्रवेश शुल्क',
    Yourrank: 'आपका रैंक',
    YouWon: 'आप जीते',
    Battle: 'बैटल',
    Howtoplay: 'गेम कैसे खेले',
    stopit: 'बंद करें',
    share: 'शेयर करें और Gems कमाए',
    CashWinner: 'GEMS विजेता',
    LastTime: 'खेल में शामिल होने का अंतिम समय',
    Amountdistribution: 'राशि वितरण',
    Leaderboard: 'लीडरबोर्ड',
    chat: 'बातचीत',
    rules: 'नियम',
    p1:
      'यह लड़ाई दो लोगों के बीच होगी। दोनों खिलाड़ी 2 मिनट तक गेम खेलकर अपना स्कोर बढ़ाएंगे।',
    p2:
      'खेले गए सभी गेम्स के स्कोर को जोड़ दिया जायेगा, इसके आधार पे निर्धारित होगा की दोनों खिलाड़ियों में से कौन जीता |',
    p3:
      'प्रतियोगी को ढूंढने का समय १ मिनट है | प्रतियोगी ना मिलने पर आपका GEMS नहीं कटेगा | बैटल के वक्त आप बैटल से बाहर नहीं जा सकते |',
    YourSuccess: 'आपकी जीत',
    Play: 'खेले',
    In: 'मे',
    Gameisstarting: 'खेल शुरू हो रहा है',
    Gameisending: 'खेल समाप्त हो रहा है',
    p4: 'आप इस टूर्नामेंट में भाग नहीं ले सकते | खिलाड़ी भरे हुए हैं |',
    Tryagain: 'दोबारा कोशिश करें',
    Finding: 'आपके प्रतियोगी को ढूंढा जा रहा है...',
    Opponentgot: 'प्रतियोगी मिल गया',
    Playagain: 'दोबारा खेलें',
    Youropponent: 'आपका प्रतियोगी',
    Yourgamebegin: 'आपका गेम कुछ ही देर में शुरू होने वाला है...',
    Gameendedrank: 'खेल समाप्त हुआ | आपका रैंक',
    competitorrank: 'है और आपके प्रतियोगी का रैंक',
    is: 'है|',
    From: 'से',
    Willruntill: 'तक चलेगा',
    p5:
      'यदि आप इस टूर्नामेंट को 50 बार खेलते हैं तो टूर्नामेंट शुल्क आपके वॉलेट में वापस कर दिया जाएगा।',
    refund: 'वापसी:',
    Youplaythis: 'आप यह खेल',
    Haveplayed: 'बार खेल चुके है |',
    Lasttimetojoin: 'खेल में शामिल होने का अंतिम समय',
    CurrentRank: 'वर्तमान पद:',
    score: 'स्कोर:',
    rank: 'श्रेणी:',
    yourrank: 'आपकी श्रेणी',
    totalprice: 'कुल कीमत',
    Period: 'अवधि',
    Itsgoing: 'चल रहा है',
    aboutobegin: 'शुरू होने वाला है',
    free: 'मुफ्त',
    Mahasangram: 'महासंग्राम',
    Lasttime: 'खेल में शामिल होने का अंतिम समय',
    haveplay: 'खेले हैं',
    totalscore: 'कुल स्कोर',
    you: 'आपने',
    p6: 'बार खेल लिया है| आगे खेलने के लिए वापस महासंग्राम से जुड़े|',
    Youenter: 'आप 1 एंट्री में',
    Canplay: ' बार खेल सकते है (मल्टीप्ल एंट्रीज मान्य है)|',
    scorefor:
      ' खेले गए सभी गेम्स के स्कोर को जोड़ दिया जायेगा, इसके आधार पर आपका रैंक निर्धारित होगा|',
    home: 'होम',
    mytournaments: 'मेरे टूर्नामेंट्स',
    Yourscore: 'आपका स्कोर',
    p7: 'आप कोई भी गेम नहीं खेल रहे हैं |',
    p8: 'आपको आगे कोई खिलाड़ी नहीं है |',
    p9: 'बार खेलने के बाद आपको महासंग्राम से वापस जुड़ना होगा|',
    wallet: 'वॉलेट',
    cash: 'GEMS',
    howtoplay: 'कैसे खेलें',
    Youplaythisgame: 'आप इस गेम को',
    win: 'जीत',
    AllGame: 'सभी खेल',
    groupChat: 'समूह बातचीत',
    chatGroupList: 'चैट समूह सूची',
    winAmtMsg: 'आपका जीता हुआ इनाम थोड़ी देर में आपके मॉल91 वॉलेट में ट्रांसफर हो जायेगा ।',
      winAmtMsgMcash:'आपका जीता हुआ इनाम थोड़ी देर में आपके मॉल91 वॉलेट में ट्रांसफर हो जायेगा ।',
    audioChatIn: 'टॉप 5 में से आपको चैट रूम के लिए चुना गया है, बाते करे ।',
    audioChatOut: 'टॉप 5 प्लेयर्स को मिलता है ऑडियो चैट करने का मौका ।',
    top: 'टॉप',
    next: 'अगले',
    joinGrpChat: "ग्रुप चैट से जुड़े",
    casinoGames: 'नये खेल',
    boardGames: 'बोर्ड खेल',
    favouriteGames: 'पसंदीदा खेल',
    cricketGames: 'क्रिकेट खेल',
    gems: 'जेम्स',
  },
  kn: {
    tournament: 'ಪಂದ್ಯಾವಳಿಯಲ್ಲಿ',
    noOpponent: 'ಯಾವುದೇ ಎದುರಾಳಿ ಇಲ್ಲ',
    Playandwin: 'ಆಡಲು ಮತ್ತು ಗೆಲ್ಲಲು',
    Weekly: 'ಸಾಪ್ತಾಹಿಕ',
    TillNow: 'ಇಲ್ಲಿಯವರೆಗೆ',
    Playing: 'ನುಡಿಸುವಿಕೆ',
    Youareplayingagame: 'ನೀವು ಆಟ ಆಡುತ್ತಿದ್ದೀರಿ',
    MyAccount: 'ನನ್ನ ಖಾತೆ',
    Tournamentisover: 'ಪಂದ್ಯಾವಳಿ ಮುಗಿದಿದೆ',
    TotalRewards: 'ಒಟ್ಟು ಪ್ರತಿಫಲಗಳು',
    Entryfees: 'ಪ್ರವೇಶ ಶುಲ್ಕ',
    Yourrank: 'ನಿಮ್ಮ ಶ್ರೇಣಿ',
    YouWon: 'ನೀವು ಗೆದ್ದಿದ್ದೀರಿ',
    Battle: 'ಕದನ',
    Howtoplay: 'ಹೇಗೆ ಆಡಬೇಕು',
    stopit: 'ಮುಚ್ಚಿ',
    share: 'ಎಂ-ನಗದು ಹಂಚಿಕೊಳ್ಳಿ ಮತ್ತು ಸಂಪಾದಿಸಿ',
    CashWinner: 'ನಗದು ವಿಜೇತ',
    LastTime: 'ಆಟಕ್ಕೆ ಸೇರಲು ಕೊನೆಯ ಬಾರಿ',
    Amountdistribution: 'ಮೊತ್ತ ವಿತರಣೆ',
    Leaderboard: 'ಲೀಡರ್ಬೋರ್ಡ್',
    chat: 'ಚಾಟ್',
    rules: 'ನಿಯಮಗಳು',
    p1:
      'ಈ ಯುದ್ಧವು ಎರಡು ಜನರ ನಡುವೆ ನಡೆಯಲಿದೆ. ಇಬ್ಬರೂ ಆಟಗಾರರು 2 ನಿಮಿಷಗಳ ಕಾಲ ಆಟಗಳನ್ನು ಆಡುವ ಮೂಲಕ ತಮ್ಮ ಸ್ಕೋರ್ ಹೆಚ್ಚಿಸಿಕೊಳ್ಳುತ್ತಾರೆ.',
    p2:
      'ಆಡಿದ ಎಲ್ಲಾ ಆಟಗಳ ಸ್ಕೋರ್‌ಗಳನ್ನು ಸೇರಿಸಲಾಗುತ್ತದೆ, ಅದರ ಆಧಾರದ ಮೇಲೆ ಎರಡೂ ಆಟಗಾರರು ಗೆಲ್ಲುತ್ತಾರೆ.',
    p3:
      'ಪ್ರತಿಸ್ಪರ್ಧಿಯನ್ನು ಹುಡುಕುವ ಸಮಯ 1 ನಿಮಿಷ. ಸ್ಪರ್ಧಿ ಅದನ್ನು ಪಡೆದರೆ ನಿಮ್ಮ ಎಂ-ಕ್ಯಾಶ್ ಕತ್ತರಿಸಲಾಗುವುದಿಲ್ಲ. ಯುದ್ಧದ ಸಮಯದಲ್ಲಿ ನೀವು ಯುದ್ಧದಿಂದ ಹೊರಗೆ ಹೋಗಲು ಸಾಧ್ಯವಿಲ್ಲ.',
    YourSuccess: 'ನಿಮ್ಮ ಗೆಲುವು',
    Play: 'ಆಡಲಾಗಿದೆ',
    In: 'ಇನ್',
    Gameisstarting: 'ಆಟ ಪ್ರಾರಂಭವಾಗುತ್ತಿದೆ',
    Gameisending: 'ಆಟವು ಕೊನೆಗೊಳ್ಳುತ್ತಿದೆ',
    p4: 'ಈ ಪಂದ್ಯಾವಳಿಯಲ್ಲಿ ನೀವು ಭಾಗವಹಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ. ಆಟಗಾರರು ತುಂಬಿದ್ದಾರೆ.',
    Tryagain: 'ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ',
    Finding: 'ನಿಮ್ಮ ಪ್ರತಿಸ್ಪರ್ಧಿಯನ್ನು ಹುಡುಕಲಾಗುತ್ತಿದೆ ...',
    Opponentgot: 'ಎದುರಾಳಿಯನ್ನು ಪಡೆದರು',
    Playagain: 'ಮತ್ತೆ ಆಟವಾಡಿ',
    Youropponent: 'ನಿಮ್ಮ ಎದುರಾಳಿ',
    Yourgamebegin: 'ನಿಮ್ಮ ಆಟ ಪ್ರಾರಂಭವಾಗಲಿದೆ ...',
    Gameendedrank: 'ಆಟ ಕೊನೆಗೊಂಡಿತು. ನಿಮ್ಮ ಶ್ರೇಣಿ',
    competitorrank: 'ಮತ್ತು ನಿಮ್ಮ ಸ್ಪರ್ಧಿಗಳ ಶ್ರೇಣಿ',
    is: 'ಇಲ್ಲ',
    From: 'ಇಂದ',
    Willruntill: 'ತನಕ ಓಡುತ್ತದೆ',
    p5:
      'ನೀವು ಈ ಪಂದ್ಯಾವಳಿಯನ್ನು 50 ಬಾರಿ ಆಡಿದರೆ, ಪಂದ್ಯಾವಳಿಯ ಶುಲ್ಕವನ್ನು ನಿಮ್ಮ ಕೈಚೀಲಕ್ಕೆ ಮರುಪಾವತಿಸಲಾಗುತ್ತದೆ.',
    refund: 'ಮರುಪಾವತಿ:',
    Youplaythis: 'ನೀವು ಇದನ್ನು ಆಡುತ್ತೀರಿ',
    Haveplayed: 'ಆಡಿದ್ದಾರೆ',
    Lasttimetojoin: 'ಆಟಕ್ಕೆ ಸೇರಲು ಕೊನೆಯ ಬಾರಿ',
    CurrentRank: 'ಪ್ರಸ್ತುತ ಶ್ರೇಣಿ:',
    score: 'ಸ್ಕೋರ್:',
    rank: 'ವರ್ಗ:',
    yourrank: 'ನಿಮ್ಮ ವರ್ಗ',
    totalprice: 'ಒಟ್ಟು ವೆಚ್ಚ',
    Period: 'ಅವಧಿ',
    Itsgoing: 'ಚಾಲನೆಯಲ್ಲಿದೆ',
    aboutobegin: 'ಪ್ರಾರಂಭವಾಗಲಿದೆ',
    free: 'ಉಚಿತ',
    Mahasangram: 'ಮಹಾಸಂಗ್ರಾಮ್',
    Lasttime: 'ಆಟಕ್ಕೆ ಸೇರಲು ಕೊನೆಯ ಬಾರಿ',
    haveplay: 'ಆಡಿದ್ದಾರೆ',
    totalscore: 'ಒಟ್ಟು ಅಂಕ',
    you: 'ನೀವು',
    p6: 'ಆಟವನ್ನು ಆಡಿದ್ದಾರೆ. ಮತ್ತಷ್ಟು ಆಡಲು ಮಹಾಸಂಗ್ರಾಮ್‌ಗೆ ಹಿಂತಿರುಗಿ.',
    Youenter: 'ನೀವು 1 ನಮೂದನ್ನು ನಮೂದಿಸಿ',
    Canplay: 'ಸಮಯವನ್ನು ಪ್ಲೇ ಮಾಡಬಹುದು (ಬಹು ನಮೂದುಗಳು ಮಾನ್ಯವಾಗಿರುತ್ತವೆ).',
    scorefor:
      'ನಿಮ್ಮ ಶ್ರೇಣಿಯನ್ನು ಅವಲಂಬಿಸಿ ಆಡುವ ಎಲ್ಲಾ ಆಟಗಳ ಸ್ಕೋರ್ ಅನ್ನು ಸೇರಿಸಲಾಗುತ್ತದೆ.',
    home: 'ಮನೆ',
    mytournaments: 'ನನ್ನ ಪಂದ್ಯಾವಳಿಗಳು',
    Yourscore: 'ನಿಮ್ಮ ಅಂಕ',
    p7: 'ನೀವು ಯಾವುದೇ ಆಟಗಳನ್ನು ಆಡುತ್ತಿಲ್ಲ.',
    p8: 'ನೀವು ಹೆಚ್ಚಿನ ಆಟಗಾರರನ್ನು ಹೊಂದಿಲ್ಲ.',
    p9: 'ಬಾರ್ ಆಡಿದ ನಂತರ ನೀವು ಮಹಾಸಂಗ್ರಾಮ್‌ಗೆ ಸೇರಬೇಕಾಗುತ್ತದೆ.',
    wallet: 'ಕೈಚೀಲ',
    cash: 'ನಗದು',
    howtoplay: 'ಹೇಗೆ ಆಡಬೇಕು',
    Youplaythisgame: 'ನೀವು ಈ ಆಟವನ್ನು ಆಡುತ್ತೀರಿ',
    win: 'ಗೆಲುವು',
    AllGame: 'ಎಲ್ಲಾ ಆಟಗಳು',
    groupChat: 'ಗುಂಪು ಚಾಟ್',
    chatGroupList: 'ಗುಂಪು ಪಟ್ಟಿ ಚಾಟ್ ಮಾಡಿ',
    winAmtMsg:
      'ವಿಜಯದ ಮೊತ್ತವನ್ನು ನಿಮ್ಮ Mall91 ವ್ಯಾಲೆಟ್‌ಗೆ ಕೆಲವೊಮ್ಮೆ ವರ್ಗಾಯಿಸಲಾಗುತ್ತದೆ.',
    winAmtMsgMcash:
      'ವಿಜಯದ ಮೊತ್ತವನ್ನು ನಿಮ್ಮ Mall91 ವ್ಯಾಲೆಟ್‌ಗೆ ಕೆಲವೊಮ್ಮೆ ವರ್ಗಾಯಿಸಲಾಗುತ್ತದೆ.',
    audioChatIn:
      'ಅಗ್ರ 5 ರಿಂದ ನಿಮ್ಮನ್ನು ಚಾಟ್ ರೂಮ್‌ಗೆ ಆಯ್ಕೆ ಮಾಡಲಾಗಿದೆ, ದಯವಿಟ್ಟು ಮಾತನಾಡಿ.',
    audioChatOut: 'ಟಾಪ್ 5 ಆಟಗಾರರಿಗೆ ಆಡಿಯೊ ಚಾಟ್ ಮಾಡಲು ಅವಕಾಶ ಸಿಗುತ್ತದೆ.',
    top: 'ಟಾಪ್',
    next: 'ಮುಂದೆ',
    joinGrpChat: "ಗುಂಪು ಚಾಟ್‌ಗೆ ಸೇರಿ",
    casinoGames: 'ಕ್ಯಾಸಿನೊ ಆಟಗಳು',
    boardGames: 'ಮಣೆಯ ಆಟಗಳು',
    favouriteGames: 'ನೆಚ್ಚಿನ ಆಟಗಳು',
    cricketGames: 'ಕ್ರಿಕೆಟ್ ಆಟಗಳು',
    gems: 'ಜೇಮ್ಸ್',
  },
  ml: {
    tournament: 'ടൂർണമെന്റ്',
    Playandwin: 'കളിച്ച് വിജയിക്കുക',
    noOpponent: 'എതിരാളികളില്ല',
    Weekly: 'പ്രതിവാര',
    TillNow: 'ഇപ്പോൾ വരെ',
    Playing: 'കളിക്കുന്നു',
    Youareplayingagame: 'നിങ്ങൾ ഒരു ഗെയിം കളിക്കുന്നു',
    MyAccount: 'എന്റെ അക്കൗണ്ട്',
    Tournamentisover: 'ടൂർണമെന്റ് അവസാനിച്ചു',
    TotalRewards: 'ആകെ പ്രതിഫലം',
    Entryfees: 'പ്രവേശന ഫീസ്',
    Yourrank: 'നിങ്ങളുടെ റാങ്ക്',
    YouWon: 'നിങ്ങൾ വിജയിച്ചു',
    Battle: 'യുദ്ധം',
    Howtoplay: 'എങ്ങനെ കളിക്കാം',
    stopit: 'അടയ്ക്കുക',
    share: 'എം-കാർഡ് പങ്കിടുക, സമ്പാദിക്കുക',
    CashWinner: 'ക്യാഷ് വിജയി',
    LastTime: 'ഗെയിമിൽ ചേരുന്ന അവസാന സമയം',
    Amountdistribution: 'തുക വിതരണം',
    Leaderboard: 'ലീഡർബോർഡ്',
    chat: 'ചാറ്റ്',
    rules: 'നിയമങ്ങൾ',
    p1:
      'ഈ യുദ്ധം രണ്ട് ആളുകൾക്കിടയിലായിരിക്കും. രണ്ട് കളിക്കാരും 2 മിനിറ്റ് ഗെയിം കളിച്ച് അവരുടെ സ്കോർ വർദ്ധിപ്പിക്കും.',
    p2:
      'കളിച്ച എല്ലാ ഗെയിമുകളുടെയും സ്‌കോറുകൾ ചേർക്കും, അതിന്റെ അടിസ്ഥാനത്തിൽ രണ്ട് കളിക്കാരും വിജയിക്കും.',
    p3:
      'എതിരാളിയെ കണ്ടെത്താനുള്ള സമയം 1 മിനിറ്റാണ്. മത്സരാർത്ഥിക്ക് അത് ലഭിക്കുകയാണെങ്കിൽ നിങ്ങളുടെ എം-ക്യാഷ് വെട്ടിക്കുറയ്ക്കില്ല. യുദ്ധസമയത്ത് നിങ്ങൾക്ക് യുദ്ധത്തിൽ നിന്ന് പുറത്തുപോകാൻ കഴിയില്ല.',
    YourSuccess: 'നിങ്ങളുടെ വിജയം',
    Play: 'കളിച്ചു',
    In: 'ൽ',
    Gameisstarting: 'കളി ആരംഭിക്കുന്നു',
    Gameisending: 'കളി അവസാനിക്കുന്നു',
    p4: 'നിങ്ങൾക്ക് ഈ ടൂർണമെന്റിൽ പങ്കെടുക്കാൻ കഴിയില്ല. കളിക്കാർ നിറഞ്ഞു.',
    Tryagain: 'വീണ്ടും ശ്രമിക്കുക',
    Finding: 'നിങ്ങളുടെ എതിരാളിയെ കണ്ടെത്തുന്നു ...',
    Opponentgot: 'എതിരാളിയെ ലഭിച്ചു',
    Playagain: 'വീണ്ടും കളിക്കുക',
    Youropponent: 'നിങ്ങളുടെ എതിരാളി',
    Yourgamebegin: 'നിങ്ങളുടെ ഗെയിം ആരംഭിക്കാൻ പോകുന്നു ...',
    Gameendedrank: 'കളി അവസാനിച്ചു. നിങ്ങളുടെ റാങ്ക്',
    competitorrank: 'നിങ്ങളുടെ എതിരാളികളുടെ റാങ്കും',
    is: 'ഉണ്ടോ?',
    From: 'മുതൽ',
    Willruntill: 'വരെ പ്രവർത്തിക്കും',
    p5:
      'നിങ്ങൾ ഈ ടൂർണമെന്റ് 50 തവണ കളിക്കുകയാണെങ്കിൽ, ടൂർണമെന്റ് ഫീസ് നിങ്ങളുടെ വാലറ്റിന് തിരികെ നൽകും.',
    refund: 'റീഫണ്ട്:',
    Youplaythis: 'നിങ്ങൾ ഇത് കളിക്കുന്നു',
    Haveplayed: 'കളിച്ചു',
    Lasttimetojoin: 'ഗെയിമിൽ ചേരുന്ന അവസാന സമയം',
    CurrentRank: 'നിലവിലെ റാങ്ക്:',
    score: 'സ്കോർ:',
    rank: 'വിഭാഗം:',
    yourrank: 'നിങ്ങളുടെ വിഭാഗം',
    totalprice: 'ആകെ ചെലവ്',
    Period: 'കാലയളവ്',
    Itsgoing: 'പ്രവർത്തിക്കുന്നു',
    aboutobegin: 'ആരംഭിക്കാൻ പോകുന്നു',
    free: 'സ .ജന്യം',
    Mahasangram: 'മഹാസംഗ്രം',
    Lasttime: 'ഗെയിമിൽ ചേരുന്ന അവസാന സമയം',
    haveplay: 'കളിച്ചു',
    totalscore: 'ആകെ സ്കോർ',
    you: 'നിങ്ങൾ',
    p6: 'ഗെയിം കളിച്ചു. കൂടുതൽ കളിക്കാൻ മഹാസംഗ്രാമിലേക്ക് മടങ്ങുക.',
    Youenter: 'നിങ്ങൾ 1 എൻ‌ട്രി നൽകുക',
    Canplay: 'സമയം പ്ലേ ചെയ്യാൻ കഴിയും (ഒന്നിലധികം എൻ‌ട്രികൾ സാധുവാണ്).',
    scorefor:
      'കളിച്ച എല്ലാ ഗെയിമുകളുടെയും സ്കോർ ചേർക്കും, നിങ്ങളുടെ റാങ്ക് അനുസരിച്ച് നിർണ്ണയിക്കപ്പെടും.',
    home: 'വീട്',
    mytournaments: 'എന്റെ ടൂർണമെന്റുകൾ',
    Yourscore: 'നിങ്ങളുടെ സ്കോർ',
    p7: 'നിങ്ങൾ ഗെയിമുകളൊന്നും കളിക്കുന്നില്ല.',
    p8: 'നിങ്ങൾക്ക് കൂടുതൽ കളിക്കാർ ഇല്ല.',
    p9: 'ബാർ കളിച്ചതിന് ശേഷം നിങ്ങൾ മഹാസംഗ്രാമിൽ ചേരേണ്ടിവരും.',
    wallet: 'വാലറ്റ്',
    cash: 'പണം',
    howtoplay: 'എങ്ങനെ കളിക്കാം',
    Youplaythisgame: 'നിങ്ങൾ ഈ ഗെയിം കളിക്കുന്നു',
    win: 'ജയിക്കുക',
    AllGame: 'എല്ലാ ഗെയിമുകളും',
    groupChat: 'ഗ്രൂപ്പ് ചാറ്റ്',
    chatGroupList: 'ചാറ്റ് ഗ്രൂപ്പ് ലിസ്റ്റ്',
    winAmtMsg:
      'വിജയത്തിന്റെ തുക എപ്പോഴെങ്കിലും നിങ്ങളുടെ Mall91 വാലറ്റിലേക്ക് മാറ്റപ്പെടും.',
      winAmtMsgMcash:'വിജയത്തിന്റെ തുക എപ്പോഴെങ്കിലും നിങ്ങളുടെ Mall91 വാലറ്റിലേക്ക് മാറ്റപ്പെടും.',
    audioChatIn:
      'ആദ്യ 5 സ്ഥാനങ്ങളിൽ നിന്ന് നിങ്ങളെ ചാറ്റ് റൂമിലേക്ക് തിരഞ്ഞെടുത്തു, ദയവായി സംസാരിക്കുക.',
    audioChatOut:
      'മികച്ച 5 കളിക്കാർക്ക് ഓഡിയോ ചാറ്റ് ചെയ്യാനുള്ള അവസരം ലഭിക്കുന്നു.',
    top: 'മുകളിൽ',
    next: 'അടുത്തത്',
    joinGrpChat: "ഗ്രൂപ്പ് ചാറ്റിൽ ചേരുക",
    casinoGames: 'കാസിനോ ഗെയിമുകൾ',
    boardGames: 'ബോർഡ് ഗെയിമുകൾ',
    favouriteGames: 'പ്രിയപ്പെട്ട ഗെയിമുകൾ',
    cricketGames: 'ക്രിക്കറ്റ് ഗെയിംസ്',
    gems: 'ജെയിംസ്',
  },
  mr: {
    tournament: 'स्पर्धा',
    Playandwin: 'खेळा आणि जिंक',
    noOpponent: 'विरोधक नाही',
    Weekly: 'साप्ताहिक',
    TillNow: 'आतापर्यंत',
    Playing: 'खेळत आहे',
    Youareplayingagame: 'तुम्ही खेळ खेळत आहात',
    MyAccount: 'माझे खाते',
    Tournamentisover: 'स्पर्धा संपली आहे',
    TotalRewards: 'एकूण पुरस्कार',
    Entryfees: 'प्रवेश शुल्क',
    Yourrank: 'तुझा दर्जा',
    YouWon: 'तू जिंकलास',
    Battle: 'लढाई',
    Howtoplay: 'कसे खेळायचे',
    stopit: 'बंद करा',
    share: 'सामायिक करा आणि एम-कॅश कमावाe',
    CashWinner: 'कॅश विजेता',
    LastTime: 'खेळ सामील होण्यासाठी गेल्या वेळी',
    Amountdistribution: 'रक्कम वितरण',
    Leaderboard: 'लीडरबोर्ड',
    chat: 'गप्पा',
    rules: 'नियम',
    p1:
      'ही लढाई दोन लोकांमध्ये असेल. दोन्ही खेळाडूंनी दोन मिनिटे गेम खेळून आपला स्कोअर वाढविला.',
    p2:
      'सर्व प्ले खेळांचे स्कोअर जोडले जातील ज्या आधारावर दोन्ही खेळाडूंनी जिंकले जाईल.',
    p3:
      'स्पर्धक शोधण्यासाठी वेळ 1 मिनिट आहे. स्पर्धकांना मिळाल्यास आपला एम-कॅश कापला जाणार नाही. युद्धाच्या वेळी आपण युद्धातून बाहेर जाऊ शकत नाही.',
    YourSuccess: 'तुझा विजय',
    Play: 'खेळला',
    In: 'मध्ये',
    Gameisstarting: 'खेळ सुरू आहे',
    Gameisending: 'खेळ संपत आहे',
    p4: 'आपण या स्पर्धेत सहभागी होऊ शकत नाही. खेळाडू पूर्ण आहेत.',
    Tryagain: 'पुन्हा प्रयत्न करा',
    Finding: 'आपला प्रतिस्पर्धी शोधत आहे ...',
    Opponentgot: 'प्रतिस्पर्धी आला',
    Playagain: 'पुन्हा खेळा',
    Youropponent: 'तुमचा विरोधक',
    Yourgamebegin: 'आपला गेम सुरू होणार आहे ...',
    Gameendedrank: 'खेळ संपला. तुझा दर्जा',
    competitorrank: 'आणि आपल्या प्रतिस्पर्धींचा दर्जा',
    is: 'तेथे आहे',
    From: 'पासून',
    Willruntill: 'पर्यंत चालत जाईल',
    p5:
      'आपण 50 वेळा हा खेळ खेळल्यास, स्पर्धेचे शुल्क आपल्या वॉलेटवर परत दिले जाईल.',
    refund: 'परतावाः',
    Youplaythis: 'तू हे खेळतोस',
    Haveplayed: 'खेळला आहे',
    Lasttimetojoin: 'खेळ सामील होण्यासाठी गेल्या वेळी',
    CurrentRank: 'वर्तमान स्थितीः',
    score: 'धावसंख्या:',
    rank: 'श्रेणी:',
    yourrank: 'तुझे श्रेणी',
    totalprice: 'एकूण किंमत',
    Period: 'कालावधी',
    Itsgoing: 'चालत आहे',
    aboutobegin: 'सुरू होणार आहे',
    free: 'विनामूल्य',
    Mahasangram: 'महासंघ',
    Lasttime: 'खेळ सामील होण्यासाठी गेल्या वेळी',
    haveplay: 'खेळला आहे',
    totalscore: 'एकूण धावसंख्या',
    you: 'तू',
    p6: 'खेळ खेळला आहे. पुढे खेळायला महासंघमकडे परत.',
    Youenter: 'आपण 1 एंट्री प्रविष्ट करा',
    Canplay: 'खेळू शकतो (एकाधिक प्रविष्ट्या वैध आहेत).',
    scorefor:
      'खेळलेल्या सर्व खेळांचे स्कोअर जोडले जातील, आपल्या रँकवर अवलंबून निश्चित केले जाईल.',
    home: 'घर',
    mytournaments: 'माझी स्पर्धा',
    Yourscore: 'आपला गुण',
    p7: 'आपण कोणतेही खेळ खेळत नाही.',
    p8: 'आपल्याकडे आणखी खेळाडू नाहीत.',
    p9: 'बार खेळल्यानंतर तुम्हाला महासंघमधे सामील होणं आवश्यक आहे.',
    wallet: 'वॉलेट',
    cash: 'रोख',
    howtoplay: 'कसे खेळायचे',
    Youplaythisgame: 'आपण हा गेम खेळता ',
    win: 'जिंकणे',
    AllGame: 'सर्व खेळ',
    groupChat: 'गट गप्पा',
    chatGroupList: 'गप्पा गट यादी',
    winAmtMsg:
      'जिंकण्याच्या रकमेत थोडा वेळ आपल्या Mall91 वॉलेटमध्ये स्थानांतरीत केले जाईल.',
      winAmtMsgMcash:'जिंकण्याच्या रकमेत थोडा वेळ आपल्या Mall91 वॉलेटमध्ये स्थानांतरीत केले जाईल.',
    audioChatIn:
      'टॉप 5 वरून आपल्याला चॅट रूमसाठी निवडले गेले आहे, कृपया बोलू द्या.',
    audioChatOut: 'टॉप 5 खेळाडूंना ऑडिओ गप्पा करण्याची संधी मिळते.',
    top: 'शीर्ष',
    next: 'पुढे',
    joinGrpChat: "गट गप्पांमध्ये सामील व्हा",
    casinoGames: 'कॅसिनो खेळ',
    boardGames: 'बोर्ड गेम',
    favouriteGames: 'आवडते खेळ',
    cricketGames: 'क्रिकेट खेळ',
    gems: 'जेम्स',
  },
  pa: {
    tournament: 'ਟੂਰਨਾਮੈਂਟ',
    Playandwin: 'ਖੇਡੋ ਅਤੇ ਜਿੱਤੋ',
    noOpponent: 'ਕੋਈ ਵਿਰੋਧੀ ਨਹੀਂ',
    Weekly: 'ਹਫਤਾਵਾਰ',
    TillNow: 'ਹੁਣ ਤੱਕ',
    Playing: 'ਖੇਡਣਾ',
    Youareplayingagame: 'ਤੁਸੀਂ ਇੱਕ ਖੇਡ ਖੇਡ ਰਹੇ ਹੋ',
    MyAccount: 'ਮੇਰਾ ਖਾਤਾ',
    Tournamentisover: 'ਟੂਰਨਾਮੈਂਟ ਖਤਮ ਹੋ ਗਿਆ ਹੈ',
    TotalRewards: 'ਕੁੱਲ ਇਨਾਮ',
    Entryfees: 'ਦਾਖ਼ਲਾ ਫੀਸ',
    Yourrank: 'ਤੁਹਾਡਾ ਰੈਂਕ',
    YouWon: 'ਤੁਸੀਂ ਜਿੱਤ ਗਏ',
    Battle: 'ਬੈਟਲ',
    Howtoplay: 'ਕਿਵੇਂ ਖੇਡਣਾ ਹੈ',
    stopit: 'ਬੰਦ ਕਰੋ',
    share: 'ਸ਼ੇਅਰ ਕਰੋ ਅਤੇ ਐਮ-ਕੈਸ਼ ਕਮਾਓ',
    CashWinner: 'ਨਕਦ ਜੇਤੂ',
    LastTime: 'ਆਖਰੀ ਵਾਰ ਖੇਡ ਵਿੱਚ ਸ਼ਾਮਲ ਹੋਣ ਲਈ',
    Amountdistribution: 'ਰਕਮ ਦੀ ਵੰਡ',
    Leaderboard: 'ਲੀਡਰਬੋਰਡ',
    chat: 'ਚੈਟ ਕਰੋ',
    rules: 'ਨਿਯਮ',
    p1:
      'ਇਹ ਲੜਾਈ ਦੋ ਲੋਕਾਂ ਦੇ ਵਿਚਕਾਰ ਹੋਵੇਗੀ. ਦੋਵੇਂ ਖਿਡਾਰੀ 2 ਮਿੰਟ ਲਈ ਗੇਮਜ਼ ਖੇਡ ਕੇ ਆਪਣਾ ਸਕੋਰ ਵਧਾਉਣਗੇ.',
    p2:
      'ਸਾਰੇ ਖੇਡੇ ਗਏ ਮੈਚਾਂ ਦੇ ਸਕੋਰਾਂ ਨੂੰ ਜੋੜਿਆ ਜਾਵੇਗਾ, ਜਿਸ ਦੇ ਅਧਾਰ ਤੇ ਦੋਹਾਂ ਖਿਡਾਰੀਆਂ ਨੇ ਜਿੱਤ ਪ੍ਰਾਪਤ ਕੀਤੀ ਜਾਵੇਗੀ.',
    p3:
      'ਮੁਕਾਬਲਾ ਲੱਭਣ ਦਾ ਸਮਾਂ 1 ਮਿੰਟ ਹੈ. ਤੁਹਾਡਾ ਐਮ-ਕੈਸ਼ ਕੱਟਿਆ ਨਹੀਂ ਜਾਵੇਗਾ ਜੇਕਰ ਮੁਕਾਬਲਾ ਕਰਨ ਵਾਲਾ ਇਸਨੂੰ ਪ੍ਰਾਪਤ ਕਰਦਾ ਹੈ. ਜੰਗ ਦੌਰਾਨ ਤੁਸੀਂ ਲੜਾਈ ਤੋਂ ਬਾਹਰ ਨਹੀਂ ਜਾ ਸਕਦੇ.',
    YourSuccess: 'ਤੁਹਾਡੀ ਜਿੱਤ',
    Play: 'ਚਲਾਇਆ',
    In: 'ਅੰਦਰ',
    Gameisstarting: 'ਖੇਡ ਸ਼ੁਰੂ ਹੋ ਰਹੀ ਹੈ',
    Gameisending: 'ਖੇਡ ਖਤਮ ਹੋ ਰਹੀ ਹੈ',
    p4: 'ਤੁਸੀਂ ਇਸ ਟੂਰਨਾਮੈਂਟ ਵਿਚ ਹਿੱਸਾ ਨਹੀਂ ਲੈ ਸਕਦੇ. ਖਿਡਾਰੀ ਪੂਰੇ ਹੁੰਦੇ ਹਨ.',
    Tryagain: 'ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ',
    Finding: 'ਆਪਣੇ ਪ੍ਰਤੀਯੋਗੀ ਨੂੰ ਲੱਭ ਰਿਹਾ ਹੈ ...',
    Opponentgot: 'ਵਿਰੋਧੀ ਨੂੰ ਮਿਲ ਗਿਆ',
    Playagain: 'ਦੁਬਾਰਾ ਖੇਡੋ',
    Youropponent: 'ਤੁਹਾਡੇ ਵਿਰੋਧੀ',
    Yourgamebegin: 'ਤੁਹਾਡਾ ਖੇਡ ਸ਼ੁਰੂ ਹੋਣ ਵਾਲਾ ਹੈ ...',
    Gameendedrank: 'ਖੇਡ ਖਤਮ ਹੋਈ. ਤੁਹਾਡਾ ਰੈਂਕ',
    competitorrank: 'ਅਤੇ ਤੁਹਾਡੇ ਮੁਕਾਬਲੇ ਦੇ ਦਰਜੇ',
    is: 'ਕੀ ਇੱਥੇ ਹੈ?',
    From: 'ਤੋਂ',
    Willruntill: 'ਰੁਕੋ',
    p5:
      'ਜੇ ਤੁਸੀਂ ਇਸ ਟੂਰਨਾਮੈਂਟ ਨੂੰ 50 ਵਾਰ ਖੇਡਦੇ ਹੋ, ਟੂਰਨਾਮੈਂਟ ਫੀਸ ਤੁਹਾਡੇ ਬਟੂਏ ਨੂੰ ਵਾਪਸ ਕੀਤੀ ਜਾਵੇਗੀ.',
    refund: 'ਰਿਫੰਡ:',
    Youplaythis: 'ਤੁਸੀਂ ਇਹ ਖੇਡਦੇ ਹੋ',
    Haveplayed: 'ਖੇਡਿਆ ਹੈ',
    Lasttimetojoin: 'ਆਖਰੀ ਵਾਰ ਖੇਡ ਵਿੱਚ ਸ਼ਾਮਲ ਹੋਣ ਲਈ',
    CurrentRank: 'ਮੌਜੂਦਾ ਸਥਿਤੀ:',
    score: 'ਸਕੋਰ:',
    rank: 'ਸ਼੍ਰੇਣੀ:',
    yourrank: 'ਤੁਹਾਡਾ ਸ਼੍ਰੇਣੀ',
    totalprice: 'ਕੁੱਲ ਲਾਗਤ',
    Period: 'ਪੀਰੀਅਡ',
    Itsgoing: 'ਚੱਲ ਰਿਹਾ ਹੈ',
    aboutobegin: 'ਸ਼ੁਰੂ ਕਰਨ ਵਾਲਾ ਹੈ',
    free: 'ਮੁਫ਼ਤ',
    Mahasangram: 'ਮਹਾਸੰਗਗ੍ਰਾਮ',
    Lasttime: 'ਆਖਰੀ ਵਾਰ ਖੇਡ ਵਿੱਚ ਸ਼ਾਮਲ ਹੋਣ ਲਈ',
    haveplay: 'ਖੇਡਿਆ ਹੈ',
    totalscore: 'ਕੁੱਲ ਸਕੋਰ',
    you: 'ਤੁਸੀਂ',
    p6: 'ਖੇਡ ਖੇਡੀ ਹੈ ਹੋਰ ਖੇਡਣ ਲਈ ਮਹਾਸੰਗਗ੍ਰਾਮ ਵਾਪਸ.',
    Youenter: 'ਤੁਸੀਂ 1 ਐਂਟਰੀ ਭਰੋ',
    Canplay: 'ਵਾਰ ਖੇਡ ਸਕਦੇ ਹੋ (ਕਈ ਐਂਟਰੀਆਂ ਜਾਇਜ਼ ਹਨ)',
    scorefor:
      'ਤੁਹਾਡੇ ਰੈਂਕ ਦੇ ਆਧਾਰ ਤੇ ਖੇਡੀਆਂ ਗਈਆਂ ਸਾਰੀਆਂ ਖੇਡਾਂ ਦਾ ਸਕੋਰ ਵੀ ਸ਼ਾਮਲ ਕੀਤਾ ਜਾਏਗਾ.',
    home: 'ਘਰ',
    mytournaments: 'ਮੇਰੀ ਟੂਰਨਾਮੇਂਟ',
    Yourscore: 'ਤੁਹਾਡਾ ਸਕੋਰ',
    p7: 'ਤੁਸੀਂ ਕੋਈ ਵੀ ਗੇਮ ਨਹੀਂ ਖੇਡ ਰਹੇ.',
    p8: 'ਤੁਹਾਡੇ ਕੋਲ ਹੋਰ ਖਿਡਾਰੀ ਨਹੀਂ ਹਨ',
    p9: 'ਬਾਰ ਚਲਾਉਣ ਤੋਂ ਬਾਅਦ ਤੁਹਾਨੂੰ ਮਹਾਸੰਗਗ੍ਰਾਮ ਨਾਲ ਜੁੜਨਾ ਪਵੇਗਾ.',
    wallet: 'ਬਟੂਆ',
    cash: 'ਨਕਦ',
    howtoplay: 'ਕਿਵੇਂ ਖੇਡਣਾ ਹੈ',
    Youplaythisgame: 'ਤੁਸੀਂ ਇਹ ਗੇਮ ਖੇਡਦੇ ਹੋ',
    win: 'ਜਿੱਤ',
    AllGame: 'ਸਾਰੇ ਗੇਮਜ਼',
    groupChat: 'ਗਰੁੱਪ ਚੈਟ',
    chatGroupList: 'ਗਰੁੱਪ ਲਿਸਟ ਦੀ ਗੱਲਬਾਤ ਕਰੋ',
    winAmtMsg:
      'ਜਿੱਤ ਦੀ ਰਕਮ ਨੂੰ ਥੋੜੇ ਸਮੇਂ ਵਿੱਚ ਤੁਹਾਡੇ Mall91 ਵਾਲਿਟ ਵਿੱਚ ਤਬਦੀਲ ਕੀਤਾ ਜਾਵੇਗਾ.',
      winAmtMsgMcash:'ਜਿੱਤ ਦੀ ਰਕਮ ਨੂੰ ਥੋੜੇ ਸਮੇਂ ਵਿੱਚ ਤੁਹਾਡੇ Mall91 ਵਾਲਿਟ ਵਿੱਚ ਤਬਦੀਲ ਕੀਤਾ ਜਾਵੇਗਾ.',
    audioChatIn:
      'ਚੋਟੀ ਦੇ 5 ਵਿੱਚੋਂ ਤੁਹਾਨੂੰ ਚੈਟ ਰੂਮ ਲਈ ਚੁਣਿਆ ਗਿਆ ਹੈ, ਕਿਰਪਾ ਕਰਕੇ ਗੱਲ ਕਰੋ.',
    audioChatOut: 'ਚੋਟੀ ਦੇ 5 ਖਿਡਾਰੀਆਂ ਨੂੰ ਆਡੀਓ ਚੈਟ ਕਰਨ ਦਾ ਮੌਕਾ ਮਿਲਦਾ ਹੈ.',
    top: 'ਸਿਖਰ ਤੇ',
    next: 'ਅਗਲਾ',
    joinGrpChat: "ਗਰੁੱਪ ਚੈਟ ਵਿੱਚ ਸ਼ਾਮਲ ਹੋਵੋ",
    casinoGames: 'ਕੈਸੀਨੋ ਖੇਡ',
    boardGames: 'ਬੋਰਡ ਗੇਮਜ਼',
    favouriteGames: 'ਮਨਪਸੰਦ ਗੇਮਜ਼',
    cricketGames: 'ਕ੍ਰਿਕਟ ਗੇਮਜ਼',
    gems: 'ਜੇਮਜ਼',
  },
  ta: {
    tournament: 'போட்டியில்',
    Playandwin: 'விளையாடி வெல்லுங்கள்',
    noOpponent: 'எந்த எதிரியும் இல்லை',
    Weekly: 'வீக்லி',
    TillNow: 'இப்போது வரை',
    Playing: 'விளையாடுதல்',
    Youareplayingagame: 'நீங்கள் ஒரு விளையாட்டு விளையாடுகிறீர்கள்',
    MyAccount: 'எனது கணக்கு',
    Tournamentisover: 'போட்டி முடிந்தது',
    TotalRewards: 'மொத்த வெகுமதிகள்',
    Entryfees: 'சேர்க்கை கட்டணம்',
    Yourrank: 'உங்கள் ரேங்க்',
    YouWon: 'நீங்கள் வென்றீர்கள்',
    Battle: 'போர்',
    Howtoplay: 'எப்படி விளையாடுவது',
    stopit: 'மூடு',
    share: 'ਸ਼ੇਅਰ ਕਰੋ ਅਤੇ ਐਮ-ਕੈਸ਼ ਕਮਾਓ',
    CashWinner: 'பண வெற்றியாளர்',
    LastTime: 'விளையாட்டில் சேர கடைசி முறை',
    Amountdistribution: 'தொகை விநியோகம்',
    Leaderboard: 'லீடர்',
    chat: 'அரட்டை',
    rules: 'விதிகள்',
    p1:
      'இந்த போர் இரண்டு பேருக்கு இடையே இருக்கும். இரு வீரர்களும் 2 நிமிடங்கள் விளையாடுவதன் மூலம் தங்கள் மதிப்பெண்ணை அதிகரிப்பார்கள்.',
    p2:
      'விளையாடிய அனைத்து ஆட்டங்களின் மதிப்பெண்களும் சேர்க்கப்படும், அதன் அடிப்படையில் இரு வீரர்களும் வெல்வார்கள். ',
    p3:
      'போட்டியாளரைக் கண்டுபிடிப்பதற்கான நேரம் 1 நிமிடம். போட்டியாளருக்கு கிடைத்தால் உங்கள் எம்-காசு குறைக்கப்படாது. போரின் போது நீங்கள் போருக்கு வெளியே செல்ல முடியாது.',
    YourSuccess: 'உங்கள் வெற்றி',
    Play: 'உடன்',
    In: 'மீது',
    Gameisstarting: 'விளையாட்டு தொடங்குகிறது',
    Gameisending: 'விளையாட்டு முடிவடைகிறது',
    p4: 'இந்த போட்டியில் நீங்கள் பங்கேற்க முடியாது. வீரர்கள் நிரம்பியுள்ளனர்.',
    Tryagain: 'மீண்டும் முயற்சிக்கவும்',
    Finding: 'உங்கள் போட்டியாளரைக் கண்டுபிடிப்பது ...',
    Opponentgot: 'எதிராளியைப் பெற்றார்',
    Playagain: 'மீண்டும் விளையாடு',
    Youropponent: 'உங்கள் எதிர்ப்பாளர்',
    Yourgamebegin: 'உங்கள் விளையாட்டு தொடங்க உள்ளது ...',
    Gameendedrank: 'ஆட்டம் முடிந்தது. உங்கள் ரேங்க்',
    competitorrank: 'உங்கள் போட்டியாளர்களின் தரவரிசை',
    is: 'இருக்கிறதா?',
    From: 'இருந்து',
    Willruntill: 'வரை இயங்கும்',
    p5:
      'இந்த போட்டியை நீங்கள் 50 முறை விளையாடினால், போட்டி கட்டணம் உங்கள் பணப்பையில் திருப்பித் தரப்படும்.',
    refund: 'திருப்பியளிக்கப்பட உள்ளது:',
    Youplaythis: 'நீங்கள் இதை விளையாடுங்கள்',
    Haveplayed: 'விளையாடியுள்ளனர்',
    Lasttimetojoin: 'விளையாட்டில் சேர கடைசி முறை',
    CurrentRank: 'தற்போதைய நிலை:',
    score: 'மதிப்பெண்:',
    rank: 'வகை:',
    yourrank: 'உங்கள் வகை',
    totalprice: 'மொத்த செலவு',
    Period: 'காலம்',
    Itsgoing: 'இயங்கும்',
    aboutobegin: 'தொடங்க உள்ளது',
    free: 'இலவச',
    Mahasangram: 'போராட்டக் களத்தில்',
    Lasttime: 'விளையாட்டில் சேர கடைசி முறை',
    haveplay: 'விளையாடியிருக்கிறார்கள்',
    totalscore: 'மொத்த மதிப்பெண்',
    you: 'நீங்கள்',
    p6: 'விளையாடியுள்ளீர்கள். மேலும் விளையாட மகாசங்கிரமுக்குத் திரும்புக.',
    Youenter: 'நீங்கள் 1 உள்ளீட்டை உள்ளிடவும்',
    Canplay: 'நேரங்களை இயக்கலாம் (பல உள்ளீடுகள் செல்லுபடியாகும்).',
    scorefor:
      'விளையாடிய அனைத்து விளையாட்டுகளுக்கான மதிப்பெண்களும் சேர்க்கப்படும், உங்கள் தரத்தைப் பொறுத்து தீர்மானிக்கப்படும்.',
    home: 'வீட்டில்',
    mytournaments: 'எனது போட்டிகள்',
    Yourscore: 'உங்கள் மதிப்பு',
    p7: 'நீங்கள் எந்த விளையாட்டுகளையும் விளையாடவில்லை.',
    p8: 'உங்களிடம் மேலும் வீரர்கள் இல்லை.',
    p9: 'பட்டியை விளையாடிய பிறகு நீங்கள் மகாசங்கிராமில் சேர வேண்டும்.',
    wallet: 'பணப்பை',
    cash: 'பண',
    howtoplay: 'எப்படி விளையாடுவது',
    Youplaythisgame: 'நீங்கள் இந்த விளையாட்டை விளையாடுகிறீர்கள்',
    win: 'வெற்றி',
    AllGame: 'அனைத்து விளையாட்டுகள்',
    groupChat: 'குழு அரட்டை',
    chatGroupList: 'அரட்டை குழு பட்டியல்',
    winAmtMsg:
      'வெற்றியின் அளவு சிறிது நேரத்தில் உங்கள் Mall91 பணப்பையை மாற்றும்.',
      winAmtMsgMcash:'வெற்றியின் அளவு சிறிது நேரத்தில் உங்கள் Mall91 பணப்பையை மாற்றும்.',
    audioChatIn:
      'முதல் 5 இடங்களிலிருந்து நீங்கள் அரட்டை அறைக்கு தேர்வு செய்யப்பட்டுள்ளீர்கள், தயவுசெய்து பேசுங்கள்.',
    audioChatOut:
      'முதல் 5 வீரர்களுக்கு ஆடியோ அரட்டை செய்ய வாய்ப்பு கிடைக்கிறது.',
    top: 'சிறந்த',
    next: 'அடுத்த',
    joinGrpChat: "குழு அரட்டையில் சேரவும்",
    casinoGames: 'கேசினோ விளையாட்டு',
    boardGames: 'பலகை விளையாட்டுகள்',
    favouriteGames: 'பிடித்த விளையாட்டுகள்',
    cricketGames: 'கிரிக்கெட் விளையாட்டு',
    gems: 'ஜேம்ஸ்',
  },
  te: {
    tournament: 'టోర్నమెంట్',
    Playandwin: 'ఆడి గెలవండి',
    noOpponent: 'ప్రత్యర్థి లేదు',
    Weekly: 'వీక్లీ',
    TillNow: 'ఇప్పటి వరకు',
    Playing: 'సాధన',
    Youareplayingagame: 'మీరు ఒక ఆట ఆడుతున్నారు',
    MyAccount: 'నా ఖాతా',
    Tournamentisover: 'టోర్నమెంట్ ముగిసింది',
    TotalRewards: 'మొత్తం రివార్డులు',
    Entryfees: 'ప్రవేశ రుసుము',
    Yourrank: 'మీ ర్యాంక్',
    YouWon: 'మీరు గెలిచారు',
    Battle: 'యుద్ధం',
    Howtoplay: 'ఎలా ఆడాలి',
    stopit: 'ఆపు దాన్ని',
    share: 'M- నగదును భాగస్వామ్యం చేయండి మరియు సంపాదించండి',
    CashWinner: 'నగదు విజేత',
    LastTime: 'ఆటలో చేరడానికి చివరిసారి',
    Amountdistribution: 'మొత్తం పంపిణీ',
    Leaderboard: 'లీడర్బోర్డ్',
    chat: 'చాట్',
    rules: 'రూల్స్',
    p1:
      'ఈ యుద్ధం ఇద్దరు వ్యక్తుల మధ్య ఉంటుంది. ఇద్దరు ఆటగాళ్ళు 2 నిమిషాలు ఆటలు ఆడటం ద్వారా వారి స్కోరును పెంచుతారు.',
    p2:
      'ఆడిన అన్ని ఆటల స్కోర్‌లు జోడించబడతాయి, వీటి ఆధారంగా ఆటగాళ్ళు ఇద్దరూ గెలుస్తారు. ',
    p3:
      'పోటీదారుని కనుగొనే సమయం 1 నిమిషం. పోటీదారుడు దాన్ని పొందినట్లయితే మీ M- నగదు కత్తిరించబడదు. యుద్ధ సమయంలో మీరు యుద్ధం నుండి బయటకు వెళ్ళలేరు.',
    YourSuccess: 'உங்கள் வெற்றி...',
    Play: 'ప్లేడ్',
    In: 'న',
    Gameisstarting: 'ఆట ప్రారంభమవుతోంది',
    Gameisending: 'ఆట ముగిసింది',
    p4: 'మీరు ఈ టోర్నమెంట్‌లో పాల్గొనలేరు. ఆటగాళ్ళు నిండి ఉన్నారు.',
    Tryagain: 'మళ్ళీ ప్రయత్నించండి',
    Finding: 'మీ పోటీదారుని కనుగొనడం ...',
    Opponentgot: 'ప్రత్యర్థి వచ్చింది',
    Playagain: 'మళ్ళీ ఆడండి',
    Youropponent: 'మీ ప్రత్యర్థి',
    Yourgamebegin: 'మీ ఆట ప్రారంభం కానుంది ...',
    Gameendedrank: 'ఆట ముగిసింది. మీ ర్యాంక్',
    competitorrank: 'మరియు మీ పోటీదారుల ర్యాంక్',
    is: 'ఉందా',
    From: 'నుండి',
    Willruntill: 'వరకు నడుస్తుంది',
    p5:
      'మీరు ఈ టోర్నమెంట్‌ను 50 సార్లు ఆడితే, టోర్నమెంట్ ఫీజు మీ వాలెట్‌కు తిరిగి ఇవ్వబడుతుంది.',
    refund: 'వాపసు:',
    Youplaythis: 'మీరు దీన్ని ఆడండి',
    Haveplayed: 'ఆడారు',
    Lasttimetojoin: 'ఆటలో చేరడానికి చివరిసారి',
    CurrentRank: 'ప్రస్తుత స్థానం:',
    score: 'స్కోరు:',
    rank: 'వర్గం:',
    yourrank: 'మీ వర్గం',
    totalprice: 'మొత్తం ఖర్చు',
    Period: 'కాలం',
    Itsgoing: 'రన్నింగ్',
    aboutobegin: 'ప్రారంభం కానుంది',
    free: 'ఉచిత',
    Mahasangram: 'యుద్ధభూమిగా',
    Lasttime: 'ఆటలో చేరడానికి చివరిసారి',
    haveplay: 'ఆడారు',
    totalscore: 'మొత్తం స్కోరు',
    you: 'మీరు',
    p6: 'ఆట ఆడారు. మరింత ఆడటానికి మహాసంగ్రామ్‌కు తిరిగి వెళ్ళు.',
    Youenter: 'మీరు 1 ఎంట్రీని నమోదు చేయండి',
    Canplay: 'సమయాలను ప్లే చేయవచ్చు (బహుళ ఎంట్రీలు చెల్లుతాయి).',
    scorefor: 'మీ ర్యాంకును బట్టి ఆడే అన్ని ఆటల స్కోరు జోడించబడుతుంది.',
    home: 'హోమ్',
    mytournaments: 'నా టోర్నమెంట్లు',
    Yourscore: 'మీ స్కోరు',
    p7: 'మీరు ఏ ఆటలూ ఆడటం లేదు.',
    p8: 'మీకు తదుపరి ఆటగాళ్ళు లేరు.',
    p9: 'బార్ ఆడిన తరువాత మీరు మహాసంగ్రాంలో చేరవలసి ఉంటుంది.',
    wallet: 'జేబు',
    cash: 'క్యాష్',
    howtoplay: 'ఎలా ఆడాలి',
    Youplaythisgame: 'మీరు ఈ ఆట ఆడండి',
    win: 'విజయం',
    AllGame: 'అన్ని ఆటలు',
    groupChat: 'గ్రూప్ చాట్',
    chatGroupList: 'చాట్ గ్రూప్ జాబితా',
    winAmtMsg:
      'గెలుపు మొత్తం కొద్దిసేపట్లో మీ Mall91 వాలెట్‌కు బదిలీ చేయబడుతుంది.',
      winAmtMsgMcash:'గెలుపు మొత్తం కొద్దిసేపట్లో మీ Mall91 వాలెట్‌కు బదిలీ చేయబడుతుంది.',
    audioChatIn:
      'టాప్ 5 నుండి మీరు చాట్ రూమ్‌కు ఎంపికయ్యారు, దయచేసి మాట్లాడండి.',
    audioChatOut: 'టాప్ 5 ఆటగాళ్లకు ఆడియో చాట్ చేసే అవకాశం లభిస్తుంది.',
    top: 'టాప్',
    next: 'తరువాత',
    joinGrpChat: "సమూహ చాట్‌లో చేరండి",
    casinoGames: 'కాసినో గేమ్స్',
    boardGames: 'బోర్డు ఆటలు',
    favouriteGames: 'ఇష్టమైన ఆటలు',
    cricketGames: 'క్రికెట్ గేమ్స్',
    gems: 'జేమ్స్',
  }
}

const i18n = new VueI18n({
  locale: 'hi', // set locale
  fallbackLocale: 'hi', // set fallback locale
  messages // set locale messages
})

export default i18n

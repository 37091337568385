<template>
  <section class="game_body homeGameBody">
    <div class="main-screen">
      <!--Inner body content started-->
      <div class="inner-container">
        <!--Common header for t91-->
        <div v-if="userInfo !== null" class="user-headpanel home_head">
          <div class="textlogo">
            <img src="/static/images/logo_g91.svg" />
          </div>
          <div class="rgt-elmcol">
            <div class="mcashCol" @click="openGamesWallet()">
              <img src="/static/images/games_wallet.svg" class="mcoin-icon" />
              {{ userInfo.gamesWalletBalance }}
            </div>

            <div class="mcashCol" @click="openShoppingWallet()">
              <img src="/static/images/shoping_wallet.svg" class="mcoin-icon" />
              {{ userInfo.shoppingBalance }}
            </div>
          </div>

          <!-- /static/images/rupee.svg <router-link
            tag="div"
            class="mcashCol"
            to="/gems-transaction-history"
          >
            <img src="/static/images/games_wallet.svg" class="mcoin-icon" />
            {{ userInfo.gamesWalletBalance }}
          </router-link>  -->

          <span class="user-icon" @click="slideLeftopen">
            <img
              v-if="userInfo.profileImageUrl"
              :src="userInfo.profileImageUrl"
            />
            <img v-else src="static/images/profile_ic.svg" />
          </span>
        </div>
        <!--Common header for t91 ends-->
        <!--List of games rendering starts-->
        <div v-if="!isEmpty(games)" class="home-gamelistbox">
          <div class="user-notes-list head-notes">
            <h3>
              ज्यादा से ज्यादा गेम्स टूर्नमेंन्ट में हिस्सा ले और जीतें ढेर सारा
              कैश प्राइज।
            </h3>
            <p>ईश्वर करे, सबसे बढ़िया व्यक्ति ही जीते।</p>
          </div>
          <!-- <div class="user-notes-list">
            <h3>जितनी देर आप गेम खेलेंगे उतने ज्यादा GEMS कमाएंगे।</h3>
            <p>
              हर आधे घंटे में आपको मिलेगा एक स्क्रैच कार्ड जहाँ से आपको मिलेंगे
              GEMS
            </p>
          </div> -->
          <CardGamesCategory />
          <!-- <FavoriteGameCategory /> -->

          <!--          <CricketGamesCategory />-->
          <!-- <BoardGamesCategory /> -->
          <!-- <JoinGroupChatCrousel /> -->
          <NewGameCategory :games="games" />
          <show-scratch-card
            v-if="showScratch"
            :scratchArrayValue="scratchArrayValue"
            :scratchSaleId="$route.query.orderId"
            :redeemId="scratchArrayValue.id"
            @closeScratch="closeScratch"
          ></show-scratch-card>
        </div>
        <!--List of games rendering ends-->
      </div>
      <!--Inner body content ends-->
    </div>
  </section>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import constants from "../constants";
import getUserInfo from "../mixins/getUserInfo";
import slideOpenClose from "../mixins/slideOpenClose";

const ShowScratchCard = () => import("./ShowScratchCard");

export default {
  name: "Home",
  components: {
    NewGameCategory: () => import("./NewGameCategory.vue"),
    CricketGamesCategory: () => import("./CricketGamesCategory.vue"),
    BoardGamesCategory: () => import("./BoardGamesCategory.vue"),
    CardGamesCategory: () => import("./CardGamesCategory.vue"),
    FavoriteGameCategory: () => import("./FavoriteGameCategory.vue"),
    JoinGroupChatCrousel: () => import("./JoinGroupChatCrousel.vue"),
    "show-scratch-card": ShowScratchCard
  },
  data() {
    return {
      spinnerLoading: false,
      xauthid: null,
      showScratch: false,
      userDataReceived: false,
      user: {
        profileImageUrl: "",
        balance: 0,
        boardCoin: 0
      },
      isActive: ""
    };
  },
  mixins: [getUserInfo, slideOpenClose],
  computed: {
    ...mapState(["games", "userInfo"])
  },
  async mounted() {
    this.changeLocale();
    this.database.ref("gameContests").off();
    this.xauthid = this.$cookie.get("X-Auth-Id");
    await this.getUserInfo(this.xauthid);
    this.$emit("switchOffListener");
    // this.getScratchCard();
  },
  methods: {
    openGamesWallet() {
      if (
        this.$cookie.get("app-name") === "keyboard91" &&
        this.$cookie.get("version-code") > 33
      ) {
        try {
          JSBridgePlugin.openNativeWallet("GAMES_WALLET");
        } catch (err) {
          console.log(err);
          // console.log("Games Wallet call in kb91 from catch()");
        }
      } else if (
        this.$cookie.get("app-name") === "browser91" &&
        this.$cookie.get("version-code") > 19
      ) {
        try {
          JSBridgePlugin.openNativeWallet("GAMES_WALLET");
        } catch (err) {
          console.log(err);
          // console.log("Games Wallet call in br91 form catch()");
        }
      } else {
        try {
          JSBridgePlugin.openNativeWallet();
        } catch (err) {
          console.log(err);
          // console.log(
          //   "Go to Else Games Wallet call in br91 in catch() without param function"
          // );
        }
      }
    },

    closeScratch() {
      this.showScratch = false;
      this.getUserInfo(this.xauthid);
    },
    changeLocale(locale) {
      this.$i18n.locale = this.$cookie.get("lang") || "hi";
      if (this.$cookie.get("lang") === "ml") {
        document.body.classList.add("activeMultiLang");
      }
    },

    openShoppingWallet() {
      if (
        this.$cookie.get("app-name") === "keyboard91" &&
        this.$cookie.get("version-code") > 33
      ) {
        try {
          JSBridgePlugin.openNativeWallet("SHOPPING");
        } catch (err) {
          console.log(err);
          // console.log("Shopping Wallet call in kb91 in catch");
        }
      } else if (
        this.$cookie.get("app-name") === "browser91" &&
        this.$cookie.get("version-code") > 19
      ) {
        try {
          JSBridgePlugin.openNativeWallet("SHOPPING");
        } catch (err) {
          console.log(err);
          // console.log("Shopping Wallet call in br91 in catch function");
        }
      } else {
        try {
          JSBridgePlugin.openNativeWallet();
        } catch (err) {
          console.log(err);
          // console.log(
          //   "Go to Else Shopping Wallet call in br91 in catch() without param function"
          // );
        }
      }
    },

    isEmpty(obj) {
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          return false;
        }
      }
    },
    getPanelColor(gameColor) {
      return {
        background: `linear-gradient(90deg, ${gameColor}, ${gameColor}, ${gameColor}, transparent)`
      };
    },

    getScratchCard() {
      const obj = {
        identifierSource: "GAMES",
        // identifierId: "123",
        applicationId: "149"
      };
      this.$http
        .post(constants.SCRATCHCARDURL + "scratch-option-by-application", obj)
        .then(function(data) {
          if (data.body) {
            if (
              data &&
              data.body.status == "ACTIVE" &&
              data.body.scratchcardDTO.cardStatus == "INVISIBLE"
            ) {
              this.showScratch = true;
              this.scratchArrayValue = data.body;
            } else if (
              this.scratchArray.status == "ACTIVE" &&
              this.scratchArray.scratchcardDTO.cardStatus == "ACTIVE"
            ) {
              this.showScratch = true;
              this.scratchArrayValue = data.body;
            }
          } else {
            console.log("already scracthed");
          }
        })
        .catch(err => console.log(err));
      //============================================testing2412
      // this.showScratch = true;
      // this.scratchArrayValue = {
      //   id: 8730971,
      //   cardIcon:
      //     "https://d3ojr524tgla9b.cloudfront.net/eedcdc9f-d347-4abd-8474-4ddea637a656.gif",
      //   displayDate: null,
      //   rewardValue: "0.2",
      //   rewardIcon:
      //     "https://d3ojr524tgla9b.cloudfront.net/f4a4293d-00c7-4f19-9c70-39cbdadc1593.png",
      //   referralCode: "QOR7M",
      //   status: "ACTIVE",
      //   scratchcardDTO: {
      //     itemId: "2406250",
      //     displayMessage: "You won",
      //     icon:
      //       "https://d3ojr524tgla9b.cloudfront.net/72bbc75a-fa16-4a6c-9a0e-2f6a25eaf1a6.png",
      //     offerEndTimeMillis: 1578594599000,
      //     amountDiscount: 5.0,
      //     rewardValue: "0.2",
      //     rewardIcon:
      //       "https://d3ojr524tgla9b.cloudfront.net/6399cad0-d38f-4956-bda8-cb7bcdbefb06.png",
      //     cardStatus: "INVISIBLE",
      //     clickUrl: "https://www.mall91.com/#/detail/2406250?source=SCRATCH",
      //     resultIcon:
      //       "https://d3ojr524tgla9b.cloudfront.net/2702ca6a-7262-4bcf-8149-b764b19b4ee0.png",
      //     offerDescription:
      //       "<b>Congratulations !</b><br><br> आपके वॉलेट मैं <b> 0.2 Mpoints </b>जमा कर दिए गए है ! ",
      //     offerDescriptionString:
      //       "आपके वॉलेट मैं 0.2 Mpoints जमा कर दिए गए है ! ",
      //     buttonName: "Claim Offer",
      //     shareMessage:
      //       "क्या आप अपने खाली समय में घर या दूकान या ऑफिस या कॉलेज से बैठे बैठे हर महीने कुछ एक्स्ट्रा कमाना चाहते हैं? क्या आप दैनिक जीवन के सामान खरीद पर ढेर बचाना चाहते हैं?\n\n Mall91 अपने WhatsApp के सोशल समूह उपयोग करके कमाई बढ़ाने का और बचत करने का बेस्ट ऐप है! इसके कीबोर्ड उपयोग करो और चैट करते हुए कमाओ! अगर दोस्तों और रिश्तेदारों को रेफेर करते हैं, तो उनकी कमाई और बचत का 1 हिस्सा आपको मिलता है - पूरे 8 लेवल तक! यहाँ से डाउनलोड करें और मेरे रेफरल कोड QOR7M का उपयोग करें -https://money91.app.link/twB3XD1OWQ",
      //     eventName: "PROMO_CLICK_PRODUCT",
      //     promoType: null,
      //     type: "NATIVE_ACTION",
      //     dataMap: { METHOD_NAME: "openMall91Tab" },
      //     miniAppModel: {
      //       id: 137,
      //       name: "Mall91",
      //       iconImageURL:
      //         "https://d19d4vszpb8ku9.cloudfront.net/09ed5235-4059-44eb-96c7-cf396f2f677d_original.png",
      //       applicationURL: "https://www.mall91.com/"
      //     }
      //   }
      // };
    }

    // getHomeData() {
    //   this.$http
    //     .get('http://tournamentbe.money91.com/contest')
    //     .then(function(data) {}, response => {})
    // }
  }
};
</script>
<style>
.all-game-modal {
  display: block !important;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(2.1);
  transform: scale(2.1);
}
.loader-style-default {
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 118px);
  /* z-index: 9999 */
}
.slide-fade-enter-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
 {
  transform: translateX(50px);
  opacity: 0;
}
</style>
